import React, { useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NoteIcon from "@mui/icons-material/Note";
import GroupIcon from "@mui/icons-material/Group";
import { useNavigate } from "react-router-dom";

const BottomNavBar = ({ url }) => {
  const theme = useTheme(); // useThemeフックを使用してテーマを取得
  const [value, setValue] = React.useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(url);
    switch (true) {
      case window.location.pathname.startsWith(`/calendar/${url}`) ||
        window.location.pathname.startsWith(`/event/${url}`):
        setValue(0);
        break;
      case window.location.pathname.startsWith(`/accounting/${url}`) ||
        window.location.pathname.startsWith(`/description/${url}`):
        setValue(1);
        break;
      case window.location.pathname.startsWith(`/notes/${url}`) ||
        window.location.pathname.startsWith(`/post/${url}`):
        setValue(2);
        break;
      case window.location.pathname.startsWith(`/group/${url}`):
        setValue(3);
        break;
      default:
        break;
    }
  }, [window.location.pathname]);

  const handleChange = (event, newValue) => {
    console.log(url);
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate(`/calendar/${url}`);
        break;
      case 1:
        navigate(`/accounting/${url}`);
        break;
      case 2:
        navigate(`/notes/${url}`);
        break;
      case 3:
        navigate(`/group/${url}`);
        break;
      default:
        break;
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels // ここでshowLabelsをtrueに設定
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        zIndex: 2000, // zIndexをテーマから取得
        bgcolor: "text.white", // 背景色をテーマから取得
      }}
    >
      <BottomNavigationAction label="予定表" icon={<CalendarTodayIcon />} />
      <BottomNavigationAction label="精算" icon={<AttachMoneyIcon />} />
      <BottomNavigationAction label="ノート" icon={<NoteIcon />} />
      <BottomNavigationAction label="しおり" icon={<GroupIcon />} />
    </BottomNavigation>
  );
};

export default BottomNavBar;
