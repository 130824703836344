import axios from "axios";
import { API_URL } from "../../shared/constant";

// リアクションの追加（サンプルデータに追加するだけ）
export const addReaction = async (url, threadId, reactionType, member_id) => {
  try {
    const response = await axios.post(
      `${API_URL.THREAD}/reaction/${url}?thread_id=${threadId}`,
      {
        reaction_type: reactionType,
        member_id: member_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    const reactionsData = response.data.reaction;
    return reactionsData;
  } catch (error) {
    console.error("リアクションの追加に失敗しました:", error);
    throw error;
  }
};
