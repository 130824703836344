import { parseISO } from "date-fns";
import axios from "axios";
import { API_URL } from "../../shared/constant";

const getAllSchedules = async (url, isDev) => {
  if (isDev) {
    const result = [
      {
        schedule_id: 34,
        title: "東京タワー",
        start_time: "2024-09-15T09:00:00+09:00",
        end_time: "2024-09-15T12:00:00+09:00",
        estimated_const: "1000",
        actual_cost: "800",
        link: "東京都港区",
        memo: "観光スポット",
        schedule_type: "01",
        color_type: "02",
        thread_id: 34,
      },
      {
        schedule_id: 35,
        title: "東京タワー",
        start_time: "2024-09-16T09:00:00+09:00",
        end_time: "2024-09-16T12:00:00+09:00",
        estimated_const: "1000",
        actual_cost: "800",
        link: "東京都港区",
        memo: "観光スポット",
        schedule_type: "02",
        color_type: "01",
        thread_id: 35,
      },
    ];
    return result.map((schedule) => ({
      ...schedule,
      start_time: parseISO(schedule.start_time),
      end_time: parseISO(schedule.end_time),
    }));
  } else {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });
    try {
      const response = await axiosInstance.get(`${API_URL.SCHEDULE}/${url}`);
      const result = response.data;
      return result.map((schedule) => ({
        ...schedule,
        start_time: parseISO(schedule.start_time),
        end_time: parseISO(schedule.end_time),
      }));
    } catch (error) {
      console.error("Error fetching schedules:", error);
      return [];
    }
  }
};

export default getAllSchedules;
