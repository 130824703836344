import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Box,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // データラベル表示用プラグイン
import { getUserName } from "../../shared/utils";
import getSplitInformation from "../../Api/accounting/getSplitInformation";
import { BOX_SHADOW, COLOR_THEME } from "../../shared/constant";

// Chart.jsの設定
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
); // ChartDataLabelsを登録

const Dashboard = ({ itineraryInfo, expenses, url }) => {
  const [splitInfo, setSplitInfo] = useState([]);
  const all_amount = expenses.reduce((sum, expense) => {
    return sum + parseInt(parseFloat(expense.total_amount));
  }, 0);
  const budgetVsActualData = {
    labels: ["予算", "費用"], // Y軸ラベルに「予算」「費用」
    datasets: [
      {
        label: "金額",
        // data: [0, all_amount], // 予算と実績費用のデータ
        data: [
          itineraryInfo.total_budget ? parseInt(itineraryInfo.total_budget) : 0,
          all_amount,
        ], // 予算と実績費用のデータ
        backgroundColor: [
          COLOR_THEME.palette.graph.budget,
          COLOR_THEME.palette.graph.amount,
        ], // 色を予算と費用で変える
      },
    ],
  }; // 予算に対する実績費用データ

  // Calculate total payment per user
  const userCosts = {};

  expenses?.forEach((expense) => {
    const totalAmount = parseInt(parseFloat(expense.total_amount));
    const splitMembers = expense.split_members;
    const costPerUser = totalAmount / splitMembers.length;

    splitMembers.forEach((member) => {
      if (userCosts[member]) {
        userCosts[member] += costPerUser;
      } else {
        userCosts[member] = costPerUser;
      }
    });
  });

  // Format data for getActualCostPerUserData
  const actualCostPerUserData = {
    labels: Object.keys(userCosts).map((userId) =>
      getUserName(itineraryInfo.users, parseInt(userId))
    ),
    datasets: [
      {
        label: "実績費用",
        data: Object.values(userCosts),
        backgroundColor: COLOR_THEME.palette.graph.individual,
      },
    ],
  };

  const maxLabelWidth = 100; // Y軸ラベルの幅を固定

  const upperChartOptions = {
    indexAxis: "y",
    layout: {
      padding: { top: 0, bottom: 30, right: 80 }, // 右側にデータラベル用の隙間を確保
    },
    scales: {
      x: {
        display: false, // X軸目盛を非表示
        grid: {
          display: false, // X軸の縦線を非表示
        },
      },
      y: {
        ticks: {
          maxWidth: maxLabelWidth, // Y軸ラベルの幅を固定
          minWidth: maxLabelWidth, // ラベルの最小幅も固定
          font: {
            size: 14, // 柔らかい印象のフォントサイズ
            weight: "500",
          },
        },
        grid: {
          display: false, // Y軸の横線を非表示
        },
      },
    },
    plugins: {
      legend: {
        display: false, // 凡例を非表示
      },
      datalabels: {
        anchor: "end",
        align: "end",
        formatter: (value) => `¥${parseInt(value).toLocaleString()}`, // データラベルとして数値を表示
        font: {
          size: 14, // 柔らかい印象を与えるフォントサイズ
          weight: "400",
        },
        color: COLOR_THEME.palette.text.primary, // 優しい色のデータラベル
      },
    },
    elements: {
      bar: {
        borderRadius: 10, // 角を丸くして柔らかい印象に
      },
    },
    maintainAspectRatio: false, // アスペクト比の固定を無効化
    aspectRatio: 2, // アスペクト比を設定して縦幅を小さく
  };

  const lowerChartOptions = {
    ...upperChartOptions, // 基本的に同じオプションを使用
    aspectRatio: 1, // 下のグラフのアスペクト比を通常に戻す
  };

  useEffect(() => {
    const fetchSplitInformation = async () => {
      const data = await getSplitInformation(url);
      setSplitInfo(data);
    };
    fetchSplitInformation();
  }, []);

  return (
    <Box sx={{ p: 2, mb: 4 }}>
      {/* 予算に対する実績費用の横棒グラフ */}
      <Box
        sx={{
          p: 2,
          bgcolor: "primary.bg", // 優しい色合いの背景
          borderRadius: 5, // ボックス自体も角丸に
          boxShadow: BOX_SHADOW, // 柔らかい影
          mb: 3, // グラフ間の隙間を追加
          position: "relative", // タイトル配置のため
          height: 200, // ボックス自体の高さも縮小
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: "text.primary" }} // タイトルのスタイルを控えめに
        >
          全体の費用
        </Typography>
        <Box sx={{ mt: 1, height: "150px" }}>
          {" "}
          {/* タイトルとグラフの間に隙間を追加 */}
          <Bar data={budgetVsActualData} options={upperChartOptions} />
        </Box>
      </Box>

      {/* ユーザごとの実績費用の横棒グラフ */}
      <Box
        sx={{
          p: 2,
          bgcolor: "primary.bg", // 上と同じ背景色
          borderRadius: 5, // ボックス自体も角丸に
          boxShadow: BOX_SHADOW, // 柔らかい影
          mb: 3, // グラフ間の隙間を追加
          position: "relative", // タイトル配置のため
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: "text.primary" }} // タイトルのスタイルを控えめに
        >
          個別の費用
        </Typography>
        <Box sx={{ mt: 1 }}>
          {" "}
          {/* タイトルとグラフの間に隙間を追加 */}
          <Bar data={actualCostPerUserData} options={lowerChartOptions} />
        </Box>
      </Box>

      {/* New Split Information Card */}
      <Box
        sx={{
          p: 2,
          bgcolor: "primary.bg", // 上と同じ背景色
          borderRadius: 5, // ボックス自体も角丸に
          boxShadow: BOX_SHADOW, // 柔らかい影
          mb: 3, // グラフ間の隙間を追加
          position: "relative", // タイトル配置のため
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: "text.primary" }} // タイトルのスタイルを控えめに
        >
          立替状況
        </Typography>
        <Box sx={{ mt: 1 }}>
          {splitInfo.length === 0 ? (
            <Typography variant="body1" color="text.primary">
              旅の支払を登録しよう！
            </Typography>
          ) : (
            <List>
              {splitInfo?.map((info) => (
                <ListItem
                  key={`${info.payer_id}-${info.payee_id}`}
                  sx={{ p: 0 }}
                >
                  <Card
                    variant="outlined"
                    sx={{ p: 0, mb: 1, width: "100%", bgcolor: "primary.bg" }}
                  >
                    <CardContent
                      sx={{
                        pb: 0,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* 下側のパディングを減らし、フレックスボックスで配置調整 */}
                      <Typography variant="body2">
                        {getUserName(
                          itineraryInfo.users,
                          parseInt(info.payer_id)
                        )}
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          &nbsp;→&nbsp;
                        </Typography>
                        {getUserName(
                          itineraryInfo.users,
                          parseInt(info.payee_id)
                        )}
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                        ¥{parseInt(info.amount).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
