import { parseContentWithUrls } from "../../shared/utils";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Avatar,
  Stack,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // 三点リーダーアイコン
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";
import { useState } from "react";

const NoteCard = ({
  title,
  content,
  creator_name,
  created_at,
  setIsEditing,
  isFull,
  post,
  handleDelete, // ノート削除用の関数を追加
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // メニューを開く処理
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // メニューを閉じる処理
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // 削除確認ダイアログを表示し、OKの場合のみ削除処理を実行
  const confirmAndDelete = () => {
    handleDelete(post); // ノートのIDなど必要な情報を渡す
    handleMenuClose(); // メニューを閉じる
  };

  // 詳細ページへの遷移などの処理
  const handleDetails = () => {
    setIsEditing(post);
    handleMenuClose(); // メニューを閉じる
  };

  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "primary.bg",
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          p: 2.5,
        }}
      >
        {/* ユーザー情報と日付セクション */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // 中央揃えに変更
            justifyContent: "space-between",
            mt: "auto",
            pb: 1,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: "primary.main",
                fontSize: "0.875rem",
              }}
            ></Avatar>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: 500 }}
            >
              {creator_name}
            </Typography>
          </Stack>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="caption" color="text.primary" sx={{ mt: 0.5 }}>
              {format(new Date(created_at), "yyyy/MM/dd HH:mm")}
            </Typography>
            {/* 三点リーダーアイコン */}
            {!isFull && (
              <IconButton
                onClick={handleMenuClick}
                color="text.primary"
                size="small"
                sx={{ ml: 1 }} // 左側に少し余白を追加
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            )}
            {/* メニュー */}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              MenuListProps={{ dense: true }} // コンパクトにするために dense を適用
              slotProps={{
                paper: {
                  elevation: 1,
                },
              }}
            >
              <MenuItem onClick={confirmAndDelete} dense>
                {" "}
                {/* dense を適用 */}
                <Typography variant="body2" fontSize="0.875rem">
                  削除
                </Typography>{" "}
                {/* フォントサイズ調整 */}
              </MenuItem>
              <MenuItem onClick={handleDetails} dense>
                {" "}
                {/* dense を適用 */}
                <Typography variant="body2" fontSize="0.875rem">
                  詳細
                </Typography>{" "}
                {/* フォントサイズ調整 */}
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        {/* タイトルセクション */}
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: "bold",
            fontSize: "1.2rem",
            color: "text.primary",
            mt: 2,
            mb: 2,
          }}
        >
          {title}
        </Typography>

        {/* コンテンツセクション */}
        <Typography
          variant="body1"
          color="text.primary"
          className={isFull ? "" : "truncate-multi-line"} // Apply class based on isFull
          sx={{
            flexGrow: 1,
            minHeight: "60px",
            whiteSpace: "pre-wrap",
            overflow: isFull ? "visible" : "hidden",
            textOverflow: isFull ? "clip" : "ellipsis",
            maxHeight: isFull ? "none" : "100px", // Adjust this value as needed
            fontSize: "0.9rem",
          }}
        >
          {parseContentWithUrls(content).map((part, index) =>
            part.type === "url" ? (
              <a
                key={index}
                href={part.content}
                target="_blank"
                rel="noopener noreferrer"
                className="note-card-a"
              >
                {part.content}
              </a>
            ) : (
              <span key={index}>{part.content}</span>
            )
          )}
        </Typography>
      </CardContent>

      {/* 編集ボタン */}
      <CardActions
        sx={{
          justifyContent: "flex-end",
          p: 1,
          pt: 0,
        }}
      >
        {isFull ? (
          <>
            <IconButton
              onClick={confirmAndDelete}
              sx={{ color: "text.grey" }}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => setIsEditing(true)}
              sx={{ color: "text.grey" }}
              size="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={() => setIsEditing(post)}
            sx={{ color: "text.grey" }}
            size="small"
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default NoteCard;
