import axios from "axios";
import { API_URL } from "../../shared/constant";

export const createExpense = async (url, expenseData) => {
  try {
    const response = await axios.post(
      `${API_URL.EXPENSE}/${url}`,
      expenseData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating schedule:", error);
    throw error;
  }
};

export default createExpense;
