// src/Api/group/getItn.js

import axios from "axios";
import { API_URL } from "../../shared/constant";

const getItn = async (url) => {
  try {
    const response = await axios.get(`${API_URL.ITINERARY}/${url}`);
    const data = response.data;
    // レスポンスデータを整形
    const formattedResult = {
      itinerary_id: data.itinerary_id,
      title: data.title,
      start_date: data.start_date,
      end_date: data.end_date,
      total_budget: data.total_budget ? parseInt(data.total_budget, 10) : null,
      users: data.members.map((member) => ({
        member_id: member.member_id,
        name: member.name,
      })),
    };
    return formattedResult;
  } catch (error) {
    console.error("Error fetching itinerary:", error);
    return null; // エラーの場合はnullを返す
  }
};

export default getItn;
