import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  IconButton,
  Box,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  Alert,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { isAfter, isSameDay, parseISO, format, addHours } from "date-fns";
import { ja } from "date-fns/locale"; // 日本語ロケールをインポート
import createNewItinerary from "../../Api/itineraries/createNewItinerary";
import createSchedule from "../../Api/schedules/createSchedule";
import getItn from "../../Api/group/getItn"; // サンプルデータ取得関数をインポート
import { BOX_SHADOW } from "../../shared/constant";
import ReactGA from "react-ga4";

const TopPage = ({ setUrl }) => {
  // 本番環境かどうかをチェック
  const isProduction = window.location.hostname === "app.tabibase.com";
  const [title, setTitle] = useState("北海道旅行-旅のしおり-");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [members, setMembers] = useState([""]);
  const [totalBudget, setTotalBudget] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [allItineraryList, setAllItineraryList] = useState({}); // オブジェクトに変更

  const handleAddMember = () => {
    setMembers([...members, ""]);
  };

  useEffect(() => {
    let url_list = localStorage.getItem("url_list")?.split(",");
    console.log(url_list);
    // useEffect内の該当部分を以下のように修正
    url_list?.map((url_now, index) => {
      getItn(url_now).then((data) => {
        if (data) {
          setAllItineraryList((prev) => ({
            ...prev,
            [url_now]: {
              // URLをキーとして使用
              url: url_now,
              title: data.title,
              startDate: data.start_date,
              endDate: data.end_date,
            },
          }));
        }
      });
    });
  }, []);

  const handleRemoveMember = (index) => {
    const newMembers = members.filter((_, i) => i !== index);
    setMembers(newMembers);
  };

  const handleMemberChange = (index, value) => {
    const newMembers = [...members];
    newMembers[index] = value;
    setMembers(newMembers);
  };

  const handleClearTitle = () => {
    setTitle("");
  };

  const validateForm = () => {
    const newErrors = {};

    if (!title.trim()) {
      newErrors.title = "タイトルは必須です。";
    }

    if (!startDate) {
      newErrors.startDate = "開始日は必須です。";
    }

    if (!endDate) {
      newErrors.endDate = "終了日は必須です。";
    }

    if (startDate && endDate) {
      const start = parseISO(startDate.toISOString());
      const end = parseISO(endDate.toISOString());
      if (!isSameDay(start, end) && isAfter(start, end)) {
        newErrors.endDate =
          "終了日は開始日と同日もしくはそれより後である必要があります。";
      }
    }

    const validMembers = members.filter((member) => member.trim() !== "");
    if (validMembers.length === 0) {
      newErrors.members = "メンバーは1人以上必要です。";
    } else if (validMembers.length !== members.length) {
      newErrors.members = "全てのメンバー名を入力してください。";
    }

    if (totalBudget && parseInt(totalBudget, 10) < 0) {
      newErrors.totalBudget = "合計予算は0以上の数値を入力してください。";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    if (isProduction) {
      ReactGA.event({
        category: "User Interaction",
        action: "Button Click",
        label: "Itinerary Create Button",
      });
    }

    const toJST = (date) => {
      if (!date) return null;
      // UTC時間に9時間を加算して日本時間に変換
      return addHours(date, 9);
    };

    const formatDate = (date) => {
      if (!date) return null;
      return format(date, "yyyy-MM-dd");
    };

    const payload = {
      title,
      start_date: formatDate(toJST(startDate)),
      end_date: formatDate(toJST(endDate)),
      members: members.filter((member) => member.trim() !== ""),
      total_budget: totalBudget
        ? parseInt(String(totalBudget).replace(/[^0-9]/g, ""), 10)
        : null,
    };

    try {
      const response = await createNewItinerary(JSON.stringify(payload));
      console.log(response);

      if (response.status === 201) {
        // レスポンスのデータを次の画面に渡す
        const url = response.data.url;
        setUrl(url);
        //TODO: サンプルの予定を2件作成する。（通常予定・終日予定で色違いで。サンプルとわかるように）

        // 栞作成APIのレスポンスからデータを取得
        const members = response.data.members; // メンバーリストを取得
        const creatorId = members?.[0]?.member_id || null; // 最初のメンバーのIDを取得

        if (!creatorId) {
          console.error("メンバー情報が正しく取得できませんでした。");
          setErrors({
            submit: "栞作成に失敗しました。もう一度お試しください。",
          });
          return;
        }
        // 栞作成時の開始日と終了日
        const itineraryStartDate = toJST(startDate);
        const itineraryEndDate = toJST(endDate);

        const sampleSchedules = [
          {
            title: "サンプル通常予定",
            start_time: itineraryStartDate.toISOString(), // 栞の開始日
            end_time: addHours(itineraryStartDate, 2).toISOString(), // 栞の開始日から2時間後
            estimated_cost: 4000,
            actual_cost: null,
            link: "",
            memo: "これはサンプルの通常予定です。",
            color_type: "03", // 色コード（自由に変更可能）
            schedule_type: "01", // 通常予定
            thread_type: 1, // 必須項目
            creator_id: creatorId,
          },
          {
            title: "サンプル終日予定",
            start_time:
              itineraryStartDate.toISOString().split("T")[0] +
              "T00:00:00+09:00", // 終了日の0時
            end_time:
              itineraryStartDate.toISOString().split("T")[0] +
              "T23:59:59+09:00", // 終了日の23時59分
            estimated_cost: 3000,
            actual_cost: null,
            link: "",
            memo: "これはサンプルの終日予定です。",
            color_type: "02", // 色コード（自由に変更可能）
            schedule_type: "02", // 終日予定
            thread_type: 1, // 必須項目
            creator_id: creatorId,
          },
        ];

        for (const schedule of sampleSchedules) {
          try {
            await createSchedule(url, schedule); // サンプル予定を作成
            console.log("サンプル予定を作成しました:", schedule.title);
          } catch (error) {
            console.error(
              "サンプル予定の作成に失敗しました:",
              schedule.title,
              error
            );
          }
        }
        navigate(`/group/${url}`);
      } else {
        console.error("Failed to submit data");
        setErrors({
          submit: "データの送信に失敗しました。もう一度お試しください。",
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setErrors({ submit: "エラーが発生しました。もう一度お試しください。" });
      setIsLoading(false);
    }
  };

  // 日付のフォーマット関数
  const formatDateRange = (startDate, endDate) => {
    const formattedStart = format(new Date(startDate), "MM/dd");
    const formattedEnd = format(new Date(endDate), "MM/dd");
    return `${formattedStart} ~ ${formattedEnd}`;
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          しおりを作成中...
        </Typography>
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Container
        maxWidth="sm"
        sx={{
          py: 4,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={2}
          sx={{ gap: 2 }}
        >
          <Box
            component="img"
            src="/images/logo.svg"
            alt="Logo"
            sx={{
              // width: 150,
              width: "100%",
              height: "auto",
            }}
          />
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            sx={{
              // width: 150,
              width: "100%",
              height: "auto",
              textAlign: "center",
            }}
          >
            旅のしおりを作ろう！
          </Typography>
        </Box>

        <Card elevation={3} sx={{ flex: 1, m: 2 }}>
          <CardContent>
            <Box component="form" noValidate autoComplete="off">
              <TextField
                fullWidth
                autoFocus
                label="タイトル"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                margin="normal"
                error={!!errors.title}
                helperText={errors.title}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearTitle} edge="end">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label="開始日"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    format="yyyy/MM/dd" // 日本式の日付フォーマット
                    slotProps={{
                      toolbar: { hidden: true },
                      textField: {
                        fullWidth: true,
                        margin: "normal",
                        error: !!errors.startDate,
                        helperText: errors.startDate,
                        required: true,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="終了日"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    format="yyyy/MM/dd" // 日本式の日付フォーマット
                    slotProps={{
                      toolbar: { hidden: true },
                      textField: {
                        fullWidth: true,
                        margin: "normal",
                        error: !!errors.endDate,
                        helperText: errors.endDate,
                        required: true,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              {members.map((member, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", mb: 0 }}
                >
                  <TextField
                    fullWidth
                    label={`メンバー名 ${index + 1}`}
                    value={member}
                    onChange={(e) => handleMemberChange(index, e.target.value)}
                    margin="normal"
                    error={!!errors.members}
                    helperText={index === 0 ? errors.members : ""}
                    InputProps={{
                      endAdornment: index > 0 && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleRemoveMember(index)}
                            edge="end"
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              ))}
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddMember}
                variant="outlined"
                fullWidth
                sx={{ mt: 1, mb: 2 }}
              >
                メンバーを追加
              </Button>
              <TextField
                fullWidth
                label="合計予算（任意）"
                type="number"
                value={totalBudget}
                onChange={(e) => setTotalBudget(e.target.value)}
                margin="normal"
                error={!!errors.totalBudget}
                helperText={errors.totalBudget}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">円</InputAdornment>
                  ),
                }}
              />
              {errors.submit && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {errors.submit}
                </Alert>
              )}
              <Button
                variant="contained"
                fullWidth
                onClick={handleSubmit}
                sx={{
                  mt: 2,
                  bgcolor: "primary.main",
                  color: "text.white",
                  fontWeight: "bold",
                }}
              >
                送信
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
      <Box sx={{ p: 3, maxWidth: 600, mb: 2 }}>
        {Object.values(allItineraryList) &&
          Object.values(allItineraryList)?.length > 0 && (
            <Typography variant="h6" sx={{ mb: 2 }}>
              作成済みのしおり
            </Typography>
          )}
        {Object.values(allItineraryList) &&
          Object.values(allItineraryList)?.length > 0 &&
          Object.values(allItineraryList)?.map((itineraryInfo, index) => (
            <Box
              key={itineraryInfo.url}
              onClick={() => {
                navigate(`/group/${itineraryInfo.url}`);
                window.location.reload();
              }}
              sx={{
                backgroundColor: "primary.bg",
                padding: "16px",
                borderRadius: "16px",
                boxShadow: BOX_SHADOW,
                mb: 2,
                cursor: "pointer",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                transition: "background-color 0.3s",
              }}
            >
              <Box sx={{ flex: 1, mr: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  {itineraryInfo.title}
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  {formatDateRange(
                    itineraryInfo.startDate,
                    itineraryInfo.endDate
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: "50%",
                  width: 32,
                  height: 32,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForwardIosIcon sx={{ color: "white", fontSize: 18 }} />
              </Box>
            </Box>
          ))}
      </Box>
    </LocalizationProvider>
  );
};

export default TopPage;
