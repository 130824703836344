import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // 三点リーダーアイコン
import { useNavigate } from "react-router-dom";
import getAlldescription from "../../Api/accounting/getAllExpenses"; // 明細情報を取得する関数をインポート
import { getUserName } from "../../shared/utils";
import deleteExpense from "../../Api/accounting/deleteExpense";
import getAllExpenses from "../../Api/accounting/getAllExpenses";
import { COLOR_MAP } from "../../shared/constant";

const Description = ({
  itineraryInfo,
  expenses,
  url,
  setIsLoading,
  setExpenses,
  schedules,
}) => {
  const [descriptionList, setDescriptionList] = useState([]);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // メニューの状態を各アイテムごとに管理するように変更
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    selectedDescription: null,
  });

  // メニューを開く処理を修正
  const handleMenuClick = (event, description) => {
    setMenuState({
      anchorEl: event.currentTarget,
      selectedDescription: description,
    });
  };

  // メニューを閉じる処理を修正
  const handleMenuClose = () => {
    setMenuState({
      anchorEl: null,
      selectedDescription: null,
    });
  };

  // 削除確認ダイアログの処理を修正
  const confirmAndDelete = () => {
    if (menuState.selectedDescription) {
      handleDelete(menuState.selectedDescription);
    }
    handleMenuClose();
  };

  useEffect(() => {
    const sortedExpenses = [...expenses].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    setDescriptionList(sortedExpenses);
  }, [expenses]);

  const handleDetailClick = (description) => {
    console.log(description);
    navigate(`/description/${url}?id=${description.expense_id}`, {
      state: { description: description },
    });
    return;
  };

  // 予定を削除する関数
  const handleDelete = async (description) => {
    if (window.confirm("本当にこの予定を削除しますか？")) {
      setIsLoading(true);
      try {
        await deleteExpense(url, description.expense_id);
        const result = await getAllExpenses(url);
        setExpenses(result);
        navigate(`/accounting/${url}`);
        return;
      } catch (err) {
        console.error("予定の削除に失敗しました:", err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const moveToEventDetail = (scheduleId) => {
    navigate(`/event/${url}?id=${scheduleId}`);
  };

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  return (
    <Box sx={{ maxWidth: 600, marginBottom: 6, padding: 2 }}>
      <List>
        {descriptionList.map((description) => {
          const splitMemberNames = description.split_members
            .map((id) => getUserName(itineraryInfo.users, id))
            .join(", ");

          return (
            <>
              <ListItem
                key={description.expense_id}
                sx={{
                  borderBottom: "1px solid #ccc",
                  alignItems: "flex-start",
                  bgcolor: "primary.bg",
                  borderRadius: "10px",
                  marginBottom: 1,
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {description.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontSize: "0.75rem", color: "text.grey" }}
                    >
                      {formatCreatedAt(description.created_at)}
                      <IconButton
                        onClick={(event) => handleMenuClick(event, description)}
                        color="text.primary"
                        size="small"
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                      <Menu
                        anchorEl={menuState.anchorEl}
                        open={Boolean(menuState.anchorEl)}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        MenuListProps={{ dense: true }}
                        slotProps={{ paper: { elevation: 1 } }}
                      >
                        <MenuItem onClick={confirmAndDelete} dense>
                          <Typography variant="body2" fontSize="0.875rem">
                            削除
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleDetailClick(menuState.selectedDescription);
                            handleMenuClose();
                          }}
                          dense
                        >
                          <Typography variant="body2" fontSize="0.875rem">
                            詳細
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {getUserName(
                              itineraryInfo.users,
                              description.payer_id
                            )}
                            さんが
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.primary"
                              sx={{ fontWeight: "bold" }}
                            >
                              {Math.round(description.total_amount)}円
                            </Typography>
                            建て替えました
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.primary"
                            sx={{ mt: 1, display: "block" }}
                          >
                            メンバー: {splitMemberNames}
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {description.schedule_id &&
                      description.schedule_id != "" && (
                        <Box
                          variant="contained"
                          sx={{
                            minWidth: "60%",
                            borderRadius: "10px",
                            backgroundColor: COLOR_MAP.find(
                              (color) =>
                                color.id ===
                                schedules?.find(
                                  (schedule) =>
                                    schedule.schedule_id ==
                                    description.schedule_id
                                )?.color_type
                            )?.code,
                            textAlign: "center",
                            px: 0.5,
                            py: 0.2,
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                            color: "text.white",
                          }}
                          onClick={() =>
                            moveToEventDetail(description.schedule_id)
                          }
                        >
                          {schedules?.find(
                            (schedule) =>
                              schedule.schedule_id == description.schedule_id
                          )?.title.length > 7
                            ? schedules
                                ?.find(
                                  (schedule) =>
                                    schedule.schedule_id ==
                                    description.schedule_id
                                )
                                ?.title.slice(0, 6) + "..."
                            : schedules?.find(
                                (schedule) =>
                                  schedule.schedule_id ==
                                  description.schedule_id
                              )?.title || ""}
                        </Box>
                      )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Link
                      href="#"
                      onClick={() => handleDetailClick(description)}
                      sx={{
                        fontSize: "0.875rem",
                        color: "text.active",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        mt: 0,
                      }}
                    >
                      詳細
                      <ArrowForwardIosIcon fontSize="0.875rem" />
                    </Link>
                  </Grid>
                </Grid>
              </ListItem>
            </>
          );
        })}
      </List>
    </Box>
  );
};

export default Description;
