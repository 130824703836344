// サンプルデータ
const sampleReplies = [
  {
    chat_id: 1,
    thread_id: 34,
    creator_id: 1,
    content: "これはスレッドID 34のサンプルの返信です。",
    created_at: "2024-09-01T10:00:00+09:00",
    updated_at: "2024-09-01T10:00:00+09:00",
    delete_id: 0,
  },
  {
    chat_id: 2,
    thread_id: 101,
    creator_id: 2,
    content: "これはスレッドID 101のサンプルの返信です。",
    created_at: "2024-09-02T12:00:00+09:00",
    updated_at: "2024-09-02T12:00:00+09:00",
    delete_id: 0,
  },
];

const sampleReactions = [
  {
    reaction_id: 1,
    thread_id: 34,
    member_id: 1,
    reaction_type: "like",
    created_at: "2024-09-01T10:00:00+09:00",
    updated_at: "2024-09-01T10:00:00+09:00",
    delete_id: 0,
  },
  {
    reaction_id: 2,
    thread_id: 101,
    member_id: 2,
    reaction_type: "like",
    created_at: "2024-09-02T12:00:00+09:00",
    updated_at: "2024-09-02T12:00:00+09:00",
    delete_id: 0,
  },
];

// 返信の取得（サンプルデータ）
export const getReplies = async (threadId) => {
  return sampleReplies.filter((reply) => reply.thread_id === threadId);
};

// 返信の追加（サンプルデータに追加するだけ）
export const addReply = async (threadId, content) => {
  const newReply = {
    chat_id: sampleReplies.length + 1,
    thread_id: threadId,
    creator_id: 1, // サンプルでは固定
    content: content,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    delete_id: 0,
  };
  sampleReplies.push(newReply);
  return newReply;
};

// リアクションの取得（サンプルデータ）
export const getReactions = async (threadId) => {
  return sampleReactions.filter((reaction) => reaction.thread_id === threadId);
};
