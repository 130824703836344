import axios from "axios";
import { API_URL } from "../../shared/constant";

// スケジュールの更新関数
const updateSchedule = async (url, id, eventData) => {
  try {
    let requestData;
    if (eventData.estimated_cost == "") {
      if (eventData.estimated_cost == "") {
        requestData = {
          ...eventData,
          estimated_cost: null,
        };
      }
    } else {
      requestData = {
        ...eventData,
      };
    }
    console.log(requestData);
    const response = await axios.put(
      `${API_URL.SCHEDULE}/${url}?schedule_id=${id}`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("スケジュールの更新に失敗しました:", error);
    throw error;
  }
};

export default updateSchedule;
