import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import getAllPosts from "../../Api/notes/getAllPosts";
import deletePost from "../../Api/notes/deletePost";
import getItn from "../../Api/group/getItn";
import "../../styles/main.css";
import NoteCard from "./NoteCard";
import { getUserName } from "../../shared/utils";

const Note = ({ setUrl, itineraryInfo, setItineraryInfo }) => {
  const navigate = useNavigate();
  const [postList, setPostList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef(null);
  const { url } = useParams();
  const [reactions, setReactions] = useState([]);
  const [reactionCounts, setReactionCounts] = useState({});

  const fetchPosts = async () => {
    setIsLoading(true);
    if (url) {
      setUrl(url);
    } else {
      navigate(`/`);
      return;
    }
    if (Object.keys(itineraryInfo).length === 0) {
      const data = await getItn(url);
      if (!data) {
        navigate("/");
        return;
      }
      setItineraryInfo(data);
    }
    const posts = await getAllPosts(url);
    setPostList(posts);
    setIsLoading(false);
  };

  const handleDelete = async (post) => {
    if (window.confirm("本当にこのノートを削除しますか？")) {
      setIsLoading(true);
      try {
        await deletePost(url, post.note_id);
        await fetchPosts();
        navigate(`/notes/${url}`);
        return;
      } catch (err) {
        console.error("ノートの削除に失敗しました:", err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 9 * 50;
    }
  }, []);

  const handleLikeClick = async () => {
    if (isLiked) {
      return;
    }
    setReactionCounts((prev) => ({
      ...prev,
      "01": (prev["01"] || 0) + 1,
    }));
    setIsLiked(true);

    try {
      const responseReactionsData = await addReaction(
        url,
        threadId,
        "01",
        localStorage.getItem("loginUserId") || users[0].member_id
      );
      setReactions(responseReactionsData);
    } catch (err) {
      console.error("リアクションの追加に失敗しました:", err);
      setError("リアクションの追加に失敗しました");
    }
  };

  const handleDetailClick = (post) => {
    if (post && post.note_id) {
      navigate(`/post/${url}?id=${post.note_id}`, {
        state: { thread_id: post.thread_id, post: post },
      });
    } else {
      navigate(`/post/${url}`);
    }
  };

  const handleNewPostClick = () => {
    navigate(`/post/${url}`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        padding: 2,
        display: "flex", // 追加
        flexDirection: "column", // 追加
        alignItems: "center", // 追加
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "left", width: "100%" }}>
        旅ノート
      </Typography>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : postList.length === 0 ? (
        <>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: "text.primary", marginTop: 4 }}
          >
            新しくノートを追加しよう！
          </Typography>
        </>
      ) : (
        <Box sx={{ flexGrow: 1, px: 1, py: 3, mb: 5, width: "100%" }}>
          <Grid container spacing={3}>
            {postList.map((post) => (
              <Grid item xs={12} sm={6} md={4} key={post.note_id}>
                <NoteCard
                  title={post.title}
                  content={post.content}
                  creator_name={getUserName(
                    itineraryInfo.users,
                    post.thread.creator_id
                  )}
                  created_at={post.thread.created_at}
                  setIsEditing={handleDetailClick}
                  isFull={false}
                  post={post}
                  handleDelete={handleDelete}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Button
        variant="contained"
        onClick={handleNewPostClick}
        sx={{
          position: "fixed",
          bottom: 70,
          right: 16,
          py: 1,
          borderRadius: "50px",
          backgroundColor: "primary.main",
          color: "text.white",
          fontWeight: "bold",
          boxShadow: 0,
          zIndex: 1000,
        }}
      >
        ＋ ノートを追加
      </Button>
    </Box>
  );
};

export default Note;
