import { parseISO } from "date-fns";
import axios from "axios";
import { API_URL } from "../../shared/constant";

const getSplitInformation = async (url) => {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
  try {
    const response = await axiosInstance.get(
      `${API_URL.EXPENSE}/summary/getSplitInformation/${url}`
    );
    const result = response.data;
    console.log(result);
    return result;
  } catch (error) {
    console.error("Error fetching schedule detail:", error);
    return null;
  }
};

export default getSplitInformation;
