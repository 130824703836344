import axios from "axios";
import { API_URL } from "../../shared/constant";

export const createPost = async (url, postData) => {
  console.log(postData);
  try {
    const response = await axios.post(`${API_URL.NOTE}/${url}`, postData, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating schedule:", error);
    throw error;
  }
};

export default createPost;
