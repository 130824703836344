import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import getPostDetail from "../../Api/notes/getPostDetail";
import ThreadComponent from "../../shared/ThreadComponent";
import getItn from "../../Api/group/getItn";
import createPost from "../../Api/notes/createPost";
import updatePost from "../../Api/notes/updatePost";
import deletePost from "../../Api/notes/deletePost";
import { getUserName, parseContentWithUrls } from "../../shared/utils";
import NoteCard from "./NoteCard";

const PostDetail = ({ setUrl, itineraryInfo, setItineraryInfo }) => {
  const { url } = useParams();
  const [searchParams] = useSearchParams();
  const note_id = searchParams.get("id");
  const location = useLocation();
  const postFromLocation = location.post; // locationオブジェクトからpostデータを取得
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const [postData, setPostData] = useState(null); // ポストデータを保持
  const [title, setTitle] = useState(""); // タイトルを保持
  const [content, setContent] = useState(""); // 内容を保持
  const [threadId, setThreadId] = useState(null); // スレッドIDを保持

  const [reactions, setReactions] = useState([]);
  const [chats, setChats] = useState([]);

  const [isEditing, setIsEditing] = useState(false);

  const [isAbleToSave, setIsAbleToSave] = useState(false);

  const [thread, setThread] = useState({});

  useEffect(() => {
    if (!url) {
      navigate("/");
      return;
    } else {
      setUrl(url);
    }
    if (Object.keys(itineraryInfo).length === 0) {
      getItn(url).then((data) => {
        if (!data) {
          navigate("/");
          return;
        }
        setItineraryInfo(data);
      });
    }
    if (postFromLocation) {
      setPostData(postFromLocation);
      setTitle(postFromLocation.title);
      setContent(postFromLocation.content);
      setThreadId(postFromLocation.thread_id);
      setChats(postFromLocation.chats);
      setReactions(postFromLocation.reactions);
    } else {
      const fetchPostData = async () => {
        try {
          const fetchInfo = await getPostDetail(url, note_id, false);
          const post = fetchInfo.note;
          console.log(post);
          if (post) {
            setPostData(post); // データが存在する場合のみ設定
            setTitle(post.title || ""); // タイトルを設定
            setContent(post.content || ""); // 内容を設定
            setThreadId(post.thread_id); // スレッドIDを設定
          }
          if (fetchInfo.reaction && fetchInfo.reaction.length > 0) {
            setReactions(fetchInfo.reaction);
          }
          if (fetchInfo.chat && fetchInfo.chat.length > 0) {
            setChats(fetchInfo.chat);
          }
          if (fetchInfo.thread) {
            setThread(fetchInfo.thread);
          }
        } catch (error) {
          console.error("Error fetching event data:", error);
        }
      };
      if (note_id) {
        fetchPostData();
      } else {
        const empPostData = {
          title: "",
          content: "",
        };
        setPostData(empPostData);
        setTitle("");
        setContent("");
      }
    }
  }, []);

  useEffect(() => {
    setIsAbleToSave(!(!title.trim() && !content.trim()));
  }, [title, content]);

  // useEffect(() => {
  //   if (!url || !note_id) {
  //     return;
  //   }
  //   const interval = setInterval(async () => {
  //     const fetchInfo = await getPostDetail(url, note_id, false);

  //     if (fetchInfo.reaction && fetchInfo.reaction.length > 0) {
  //       setReactions(fetchInfo.reaction);
  //     }

  //     if (fetchInfo.chat && fetchInfo.chat.length > 0) {
  //       setChats(fetchInfo.chat);
  //     }
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [url, note_id]);

  const validateForm = () => {
    const newErrors = {};

    if (!title.trim() && !content.trim()) {
      newErrors.title = "タイトルは必須です。";
      newErrors.content = "内容は必須です。";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    console.log(postData);
    let updatedPost =
      note_id === null
        ? {
            ...postData,
            content: content,
            title: title,
            thread_type: "3",
            creator_id:
              localStorage.getItem("loginUserId") ||
              itineraryInfo.users[0].member_id, // localStorageから取得したloginUserId
          }
        : {
            ...postData,
            content: content,
            title: title,
            thread_type: "3",
            creator_id: thread.creator_id,
          };

    try {
      setIsSaving(true); // 保存処理開始時にtrueに設定
      if (note_id === null) {
        // 新規作成の場合
        const respose = await createPost(url, updatedPost);
        setThreadId(respose.thread_id);
        console.log("保存されたイベント:", updatedPost);
        navigate(-1);
      } else {
        // 更新の場合
        await updatePost(url, note_id, updatedPost);
        console.log("更新されたイベント:", updatedPost);
        navigate(-1);
      }
    } catch (err) {
      console.error("イベントの保存に失敗しました:", err);
    } finally {
      setIsSaving(false); // 保存処理終了時にfalseに設定
    }
  };

  const handleDelete = async (post) => {
    if (window.confirm("本当にこのイベントを削除しますか？")) {
      setIsSaving(true);
      try {
        await deletePost(url, note_id);
        navigate(`/notes/${url}`);
      } catch (err) {
        console.error("イベントの削除に失敗しました:", err);
      } finally {
        setIsSaving(false);
      }
    }
  };

  if (!postData || !itineraryInfo || isSaving) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThreadComponent
      threadId={threadId}
      threadType={3}
      handleSaveClick={handleSave}
      chatsData={chats}
      reactionsData={reactions}
      users={itineraryInfo.users}
      url={url}
      setChats={setChats}
      setReactions={setReactions}
      isViewing={!isEditing}
      setIsEditing={setIsEditing}
      isAbleToSave={isAbleToSave}
    >
      {note_id === null || isEditing ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">
              ノートを{note_id === null ? "作成" : "編集"}
            </Typography>
            {note_id !== null && (
              <IconButton onClick={handleDelete} sx={{ color: "text.grey" }}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>

          {/* タイトルの入力ボックス */}
          <Box sx={{ marginBottom: 2 }}>
            <TextField
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              label="タイトル"
              variant="outlined"
              error={!!errors.title}
              helperText={errors.title}
              autoFocus
            />
          </Box>

          {/* 内容の入力ボックス */}
          <Box sx={{ marginBottom: 2 }}>
            <TextField
              fullWidth
              value={content}
              onChange={(e) => setContent(e.target.value)}
              label="内容"
              variant="outlined"
              multiline
              minRows={4}
              maxRows={10}
              error={!!errors.content}
              helperText={errors.content}
            />
          </Box>
        </>
      ) : (
        <NoteCard
          title={title}
          content={content}
          creator_name={getUserName(itineraryInfo.users, thread.creator_id)}
          created_at={thread.created_at}
          setIsEditing={setIsEditing}
          isFull={true}
          handleDelete={handleDelete}
        />
      )}
    </ThreadComponent>
  );
};

export default PostDetail;
