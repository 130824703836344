import axios from "axios";
import { API_URL } from "../../shared/constant";

export const createSchedule = async (url, eventData) => {
  let requestData;
  if (eventData.estimated_cost == "") {
    if (eventData.estimated_cost == "") {
      requestData = {
        ...eventData,
        estimated_cost: null,
      };
    }
  } else {
    requestData = {
      ...eventData,
    };
  }
  console.log(requestData);
  try {
    const response = await axios.post(
      `${API_URL.SCHEDULE}/${url}`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating schedule:", error);
    throw error;
  }
};

export default createSchedule;
