import React, { useState, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate, // Navigate をインポート
} from "react-router-dom";
import ReactGA from "react-ga4";
import MyCalendar from "./components/Calendar/Calendar";
import EventDetail from "./components/Calendar/EventDetail";
import Accounting from "./components/Accounting/Accounting";
import Description from "./components/Accounting/Description";
import DescriptionDetail from "./components/Accounting/DescriptionDetail";
import Notes from "./components/Notes/Notes";
import PostDetail from "./components/Notes/PostDetail";
import Group from "./components/Group/Group";
import BottomNavBar from "./shared/BottomNavBar";
import theme from "./shared/theme";
import Top from "./components/Top/Top";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Typography,
} from "@mui/material";

function UserSelectionDialog({ open, users, onSelect, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: 16,
          maxWidth: 400,
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" align="center" gutterBottom>
          あなたの名前を選んでください！
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          {users &&
            users.map((user) => (
              <ListItem
                button
                key={user.member_id}
                onClick={() => onSelect(user.member_id)}
                sx={{
                  borderRadius: 2,
                  mb: 1,
                  "&:hover": {
                    backgroundColor: "primary.bg",
                  },
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "primary.main" }}></Avatar>
                </ListItemAvatar>
                <ListItemText primary={user.name} />
              </ListItem>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

function AppContent() {
  // 本番環境かどうかをチェック
  const isProduction = window.location.hostname === "app.tabibase.com";
  console.log("isProduction", window.location.hostname);
  // Google Analytics の初期化
  if (isProduction) {
    ReactGA.initialize("G-CCEPBZBGSF");
  }
  const location = useLocation();
  const [url, setUrl] = useState("");
  const [itineraryInfo, setItineraryInfo] = useState({});
  const [loginUserId, setLoginUserId] = useState(
    localStorage.getItem("loginUserId")
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (itineraryInfo.users && itineraryInfo.users.length > 0) {
      const userExists = itineraryInfo.users.some(
        (user) => user.member_id == loginUserId
      );
      if (!loginUserId || !userExists) {
        setDialogOpen(true);
      }
    }
  }, [loginUserId, itineraryInfo]);

  const handleUserSelect = (member_id) => {
    setLoginUserId(member_id);
    localStorage.setItem("loginUserId", member_id);
    setDialogOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    // トラッキング対象のパスを配列で定義
    const trackablePaths = [
      "/accounting",
      "/calendar",
      "/notes",
      "/group",
      "/",
    ];
    const titleMap = {
      "": "トップ",
      calendar: "予定表",
      accounting: "精算",
      notes: "ノート",
      group: "しおり",
    };

    // 現在のパスが対象パスで始まるかチェック
    const shouldTrack = trackablePaths.some((path) =>
      path === "/"
        ? location.pathname === "/"
        : location.pathname.startsWith(path)
    );

    // 条件に合う場合のみトラッキング情報を送信
    if (isProduction && shouldTrack) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: titleMap[location.pathname.split("/")[1]] || "unknown",
      });
    }
  }, [location]);

  return (
    <>
      <div
        style={{
          paddingBottom:
            "56px" /* Adjust this value based on BottomNavBar height */,
        }}
      >
        <Routes>
          <Route path="/" element={<Top setUrl={setUrl} />} />
          <Route
            path="/calendar/:url"
            element={
              <MyCalendar
                setUrl={setUrl}
                itineraryInfo={itineraryInfo}
                setItineraryInfo={setItineraryInfo}
              />
            }
          />
          <Route
            path="/event/:url"
            element={
              <EventDetail
                setUrl={setUrl}
                itineraryInfo={itineraryInfo}
                setItineraryInfo={setItineraryInfo}
              />
            }
          />
          <Route
            path="/accounting/:url"
            element={
              <Accounting
                setUrl={setUrl}
                itineraryInfo={itineraryInfo}
                setItineraryInfo={setItineraryInfo}
              />
            }
          />
          <Route
            path="/notes/:url"
            element={
              <Notes
                setUrl={setUrl}
                itineraryInfo={itineraryInfo}
                setItineraryInfo={setItineraryInfo}
              />
            }
          />
          <Route
            path="/group/:url"
            element={
              <Group
                setUrl={setUrl}
                itineraryInfo={itineraryInfo}
                setItineraryInfo={setItineraryInfo}
                setDialogOpen={setDialogOpen}
              />
            }
          />
          <Route
            path="/post/:url"
            element={
              <PostDetail
                setUrl={setUrl}
                itineraryInfo={itineraryInfo}
                setItineraryInfo={setItineraryInfo}
              />
            }
          />
          <Route
            path="/description/:url"
            element={
              <DescriptionDetail
                setUrl={setUrl}
                itineraryInfo={itineraryInfo}
                setItineraryInfo={setItineraryInfo}
              />
            }
          />
          {/* "*" パスに対して "/" にリダイレクト */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      {location.pathname !== "/" && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: "5",
          }}
        >
          <BottomNavBar url={url} />
        </div>
      )}
      <UserSelectionDialog
        open={dialogOpen}
        users={itineraryInfo.users || []}
        onSelect={handleUserSelect}
        onClose={handleDialogClose}
      />
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;
