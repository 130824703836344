// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-card-a {
  color: #0066cc;
  text-decoration: underline;
  word-break: break-all; /* 長いURLが枠からはみ出ないようにする */
}

.truncate-multi-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines to show */
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/styles/main.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;EAC1B,qBAAqB,EAAE,wBAAwB;AACjD;;AAEA;EACE,oBAAoB;EACpB,4BAA4B;EAC5B,qBAAqB,EAAE,4BAA4B;EACnD,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".note-card-a {\n  color: #0066cc;\n  text-decoration: underline;\n  word-break: break-all; /* 長いURLが枠からはみ出ないようにする */\n}\n\n.truncate-multi-line {\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 3; /* Number of lines to show */\n  overflow: hidden;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
