// src/Api/accounting/getAllExpenses.js
import axios from "axios";
import { API_URL } from "../../shared/constant";

const getAllExpenses = async (url) => {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
  try {
    const response = await axiosInstance.get(`${API_URL.EXPENSE}/${url}`);
    const result = response.data;
    return result;
  } catch (error) {
    console.error("Error fetching schedules:", error);
    return [];
  }
};

export default getAllExpenses;
