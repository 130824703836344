import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { format, addDays, differenceInCalendarDays } from "date-fns";
import getAllSchedules from "../../Api/schedules/getAllSchedules";
import getItn from "../../Api/group/getItn";
import { useNavigate, useParams } from "react-router-dom";
import { COLOR_MAP } from "../../shared/constant";

const MyCalendar = ({ setUrl, itineraryInfo, setItineraryInfo }) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);
  const [displayedDays, setDisplayedDays] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const { url } = useParams();
  const [hasScrolled, setHasScrolled] = useState(false); // スクロール制御用のフラグを追加

  const times = Array.from({ length: 25 }, (_, i) => i);

  useEffect(() => {
    const fetchItinerary = async () => {
      setIsLoading(true);
      console.log(url);

      // URLが存在しない場合はホームにリダイレクト
      if (!url && url == "") {
        navigate(`/`);
      } else {
        console.log(url);
        setUrl(url);
      }

      let start;
      let end;

      // itineraryInfoが空の場合はgetItnから期間を取得
      if (Object.keys(itineraryInfo).length === 0) {
        const data = await getItn(url);
        if (!data) {
          navigate("/");
          return;
        }
        start = new Date(data.start_date);
        end = new Date(data.end_date);
        setItineraryInfo(data);
      } else {
        start = new Date(itineraryInfo.start_date);
        end = new Date(itineraryInfo.end_date);
      }

      // カレンダー表示のために選択範囲と表示日数を設定
      setSelectedRange([start, end]);
      const daysDifference = differenceInCalendarDays(end, start) + 1;
      const numOfDays = Math.min(3, Math.max(1, daysDifference));
      setDisplayedDays(
        Array.from({ length: numOfDays }, (_, i) => addDays(start, i))
      );

      setIsLoading(false);
    };

    const fetchEvents = async () => {
      setIsLoading(true);
      const schedules = await getAllSchedules(url, false);
      const formattedEvents = schedules.map((schedule) => ({
        schedule_id: schedule.schedule_id,
        title: schedule.title,
        start_time: schedule.start_time,
        end_time: schedule.end_time,
        schedule_type: schedule.schedule_type,
        color_type: getColorFromCode(schedule.color_type),
        thread_id: schedule.thread_id,
      }));
      setEvents(formattedEvents);
      setIsLoading(false);
    };

    fetchItinerary();
    fetchEvents();
  }, []);

  useEffect(() => {
    console.log("hasScrolled", hasScrolled);
    console.log("isLoading", isLoading);
    if (isLoading || hasScrolled || events.length == 0) return;

    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour < 9) {
      // 現在時刻が9時より前の場合

      // 表示されている日のイベントを取得（終日イベントと複数日イベントを除外）
      const eventsOnDisplayedDays = events.filter(
        (event) =>
          displayedDays.some(
            (day) =>
              format(day, "yyyy-MM-dd") ===
              format(event.start_time, "yyyy-MM-dd")
          ) &&
          event.schedule_type === "01" && // 終日イベントを除外
          format(event.start_time, "yyyy-MM-dd") ===
            format(event.end_time, "yyyy-MM-dd") // 複数日イベントを除外
      );

      if (eventsOnDisplayedDays.length > 0) {
        // 各イベントの開始時刻（時刻部分のみ、0時からの分数）を計算
        const eventsWithTime = eventsOnDisplayedDays.map((event) => {
          const hours = event.start_time.getHours();
          const minutes = event.start_time.getMinutes();
          const totalMinutes = hours * 60 + minutes;
          return { event, totalMinutes };
        });

        // 最も早い時刻を持つイベントを特定
        const earliestEvent = eventsWithTime.reduce((earliest, current) => {
          return current.totalMinutes < earliest.totalMinutes
            ? current
            : earliest;
        }, eventsWithTime[0]);

        // 最も早い時刻にスクロール
        const hour = Math.floor(earliestEvent.totalMinutes / 60);
        const element = document.getElementById(`time-${hour}`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          setHasScrolled(true);
        } else {
          console.warn(`Element with id time-${hour} not found.`);
        }
      } else {
        // イベントがない場合もデフォルトの時刻（9時）にスクロール
        const element = document.getElementById("time-9");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          setHasScrolled(true);
        } else {
          console.warn("Element with id time-9 not found.");
        }
      }
    } else {
      // 現在時刻が9時以降の場合、イベントの有無にかかわらず9時にスクロール
      const element = document.getElementById("time-9");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        setHasScrolled(true);
      } else {
        console.warn("Element with id time-9 not found.");
      }
    }
  }, [isLoading, hasScrolled, events, displayedDays]);

  // useEffect(() => {
  //   const element = document.getElementById("nine");
  //   element?.scrollIntoView();
  // }, [isLoading]);

  const handleDetailClick = (event, date) => {
    if (event && event.schedule_id) {
      navigate(`/event/${url}?id=${event.schedule_id}`, {
        state: { thread_id: event.thread_id },
      });
    } else if (date) {
      console.log(date);
      navigate(`/event/${url}?date=${date.getTime().toString()}`);
    } else {
      navigate(`/event/${url}`);
    }
  };

  const handleNewEventClick = () => {
    navigate(`/event/${url}`);
  };

  const handleSwipeChangeIndex = (index) => {
    const start = selectedRange[0];
    const numOfDays = Math.min(3, Math.max(1, displayedDays.length));
    setDisplayedDays(
      Array.from({ length: numOfDays }, (_, i) => addDays(start, index + i))
    );
    setStartIndex(index);
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      handleSwipeChangeIndex(startIndex - 1);
    }
  };

  const handleNext = () => {
    const start = selectedRange[0];
    const end = selectedRange[1];
    const daysDifference = differenceInCalendarDays(end, start) + 1;
    if (startIndex < daysDifference - 3) {
      handleSwipeChangeIndex(startIndex + 1);
    }
  };

  const getColorFromCode = (code) => {
    const color = COLOR_MAP.find((color) => color.id === code);
    return color ? color.code : "primary.main"; // デフォルトの色を設定
  };

  const japaneseDays = ["日", "月", "火", "水", "木", "金", "土"];

  // 現在時刻を定期的に更新する
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // 1分ごとに更新

    return () => clearInterval(interval); // クリーンアップ
  }, []);

  // 現在時刻に基づく線の位置を計算する
  const currentLinePosition = () => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    return (hours + minutes / 60) * 50 + 25; // 1時間あたり50+25pxの高さ
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 1, maxWidth: { xs: "100%", sm: "100%" }, margin: "0 auto" }}>
      <Grid container direction="column">
        {/* ヘッダー部分 */}
        <Grid
          item
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 4,
          }}
        >
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              mb: 2,
              flexDirection: "column",
            }}
          >
            {/* 日付スライダーの行 */}
            <Grid container sx={{ alignItems: "center", mt: 1 }}>
              <Grid item xs={1} sm={1} />
              <Grid item xs={1} sm={1}>
                {/* 戻るボタン */}
                {differenceInCalendarDays(selectedRange[1], selectedRange[0]) +
                  1 >
                  3 &&
                  startIndex > 0 && (
                    <IconButton
                      onClick={handlePrev}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  )}
              </Grid>

              <Grid item xs={9} sm={9}>
                {/* 日付スライダー */}
                <Grid
                  container
                  justifyContent="center"
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  {displayedDays.map((day, index) => (
                    <Grid
                      item
                      xs={12 / displayedDays.length}
                      key={day.toString()}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "14px" }}>
                          {japaneseDays[day.getDay()]}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: "18px" }}>
                          {format(day, "M/dd")}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={1} sm={1}>
                {/* 次へボタン */}
                {differenceInCalendarDays(selectedRange[1], selectedRange[0]) +
                  1 >
                  3 &&
                  startIndex <
                    differenceInCalendarDays(
                      selectedRange[1],
                      selectedRange[0]
                    ) -
                      2 && (
                    <IconButton
                      onClick={handleNext}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  )}
              </Grid>
              <Grid item xs={1} sm={1} />
            </Grid>

            {/* 日付イベントの行 */}
            <Grid container>
              <Grid item xs={1} sm={1} />
              <Grid item xs={1} sm={1} />
              <Grid item xs={9} sm={9}>
                <Grid
                  container
                  justifyContent="center"
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  {displayedDays.map((day, index) => (
                    <Grid
                      item
                      xs={12 / displayedDays.length}
                      key={day.toString()}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {events
                        .filter(
                          (event) =>
                            day.setHours(23, 59, 59, 999) >=
                              new Date(event.start_time) &&
                            day.setHours(0, 0, 0, 0) <=
                              new Date(event.end_time) &&
                            event.schedule_type === "02"
                        )
                        .map((event, idx) => {
                          const n = events.filter(
                            (event) =>
                              day.setHours(23, 59, 59, 999) >=
                                new Date(event.start_time) &&
                              day.setHours(0, 0, 0, 0) <=
                                new Date(event.end_time) &&
                              event.schedule_type === "02"
                          ).length;
                          return (
                            <Box
                              key={idx}
                              onClick={() => handleDetailClick(event)}
                              sx={{
                                width: `calc(${100 / n}% - 1px)`, // 幅を小さくして中央に揃える
                                height: "30px", // 固定の高さを設定
                                display: "flex", // Flexboxで中央揃えを設定
                                overflow: "hidden", // ボックスの横幅を超える部分を隠す
                                bgcolor: event.color_type,
                                color: "text.white",
                                borderRadius: 1,
                                padding: "2px 3px",
                                margin: "2px 1px",
                                cursor: "pointer",
                                fontSize: "10px",
                                fontWeight: "bold",
                                textAlign: "left",
                                overflow: "hidden", // ボックスの横幅を超える部分を隠す
                              }}
                            >
                              {event.title}
                            </Box>
                          );
                        })}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={1} sm={1} />
            </Grid>
          </Paper>
        </Grid>

        {/* スケジュール部分 */}
        <Grid
          container
          sx={{
            position: "relative",
            width: "100%",
            height: `${times.length * 50 + 20}px`,
          }}
        >
          {/* 時間目盛り */}
          <Grid
            item
            xs={1}
            sm={1}
            sx={{ position: "relative", height: "100%" }}
          >
            {times.map((time) => (
              <Box
                key={time}
                sx={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {time < 7 && (
                  <div style={{ heigt: "0" }} id={`time-${time + 2}`} />
                )}
                {time == 7 && <div style={{ heigt: "0" }} id="time-9" />}
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                    },
                  }}
                >{`${time}:00`}</Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "0%",
                    left: "101%",
                    width: "100%",
                    height: "50%",
                    borderBottom: "1px solid lightgrey",
                  }}
                />
              </Box>
            ))}
            {/* 現在時刻を示す横線 */}
            <Box
              sx={{
                position: "absolute",
                top: `${currentLinePosition()}px`, // 現在の時刻に基づく位置
                left: "100%",
                width: "calc(100% * 11)",
                height: "2px",
                bgcolor: "primary.currentLine",
                zIndex: 2, // 他の要素の上に表示する
              }}
            />
          </Grid>

          <Grid item xs={1} sx={{ height: "10px" }} />

          <Grid
            item
            xs={9}
            sm={9}
            sx={{ position: "relative", height: "100%" }}
          >
            <Grid container sx={{ position: "relative", width: "100%" }}>
              {displayedDays.map((day, index) => (
                <Grid item xs={12 / displayedDays.length} key={index}>
                  {times.map((time) => (
                    <Box
                      key={time}
                      sx={{
                        height: 50,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "0%",
                          width: "100%",
                          height: "50%",
                          borderBottom: "1px solid lightgrey",
                        }}
                        onClick={() => {
                          const date = new Date(day);
                          date.setHours(time - 1, 0);
                          if (
                            format(date, "yyyy-MM-dd") ===
                            format(day, "yyyy-MM-dd")
                          ) {
                            handleDetailClick(null, date);
                          }
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          width: "100%",
                          height: "50%",
                        }}
                        onClick={() => {
                          const date = new Date(day);
                          date.setHours(time, 0);
                          console.log("date", date);
                          console.log("day", day);
                          if (
                            format(date, "yyyy-MM-dd") ===
                            format(day, "yyyy-MM-dd")
                          ) {
                            handleDetailClick(null, date);
                          }
                        }}
                      />
                    </Box>
                  ))}
                </Grid>
              ))}
            </Grid>
            {/* ここからabsolute */}
            {displayedDays.map((day, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: `${(index / displayedDays.length) * 100}%`,
                    width: `${100 / displayedDays.length}%`,
                    borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                    borderRight: "1px solid lightgrey",
                    height: "100%",
                  }}
                >
                  {events
                    .filter(
                      (event) =>
                        day.setHours(23, 59, 59) >= event.start_time &&
                        day.setHours(0, 0, 0) <= event.end_time &&
                        event.schedule_type === "01"
                    )
                    .map((event, idx, filteredEvents) => {
                      if (format(event.end_time, "HH-mm") === "00-00") {
                        event.end_time.setMinutes(
                          event.end_time.getMinutes() - 1
                        );
                      }
                      if (
                        format(event.start_time, "yyyy-MM-dd-HH-mm") ===
                        format(event.end_time, "yyyy-MM-dd-HH-mm")
                      ) {
                        event.end_time.setMinutes(
                          event.end_time.getMinutes() + 30
                        );
                      }
                      // 同じ時間に開始されるイベントを重複として扱う
                      const overlappingEvents = filteredEvents.filter(
                        (otherEvent) =>
                          event.start_time < otherEvent.end_time &&
                          event.end_time > otherEvent.start_time
                      );
                      const n = overlappingEvents.length; // 重複イベントの数
                      const eventIndex = overlappingEvents.findIndex(
                        (e) => e.schedule_id === event.schedule_id
                      ); // 現在のイベントのインデックス

                      return (
                        <Box
                          key={idx}
                          onClick={() => handleDetailClick(event)}
                          sx={{
                            position: "absolute",
                            top: `${
                              format(event.end_time, "yyyy-MM-dd") !==
                                format(day, "yyyy-MM-dd") &&
                              format(event.start_time, "yyyy-MM-dd") !==
                                format(day, "yyyy-MM-dd")
                                ? 25
                                : format(event.start_time, "yyyy-MM-dd") !==
                                  format(day, "yyyy-MM-dd")
                                ? 25
                                : (event.start_time.getHours() +
                                    event.start_time.getMinutes() / 60) *
                                    50 +
                                  25
                            }px`,
                            height: `${
                              format(event.end_time, "yyyy-MM-dd") !==
                                format(day, "yyyy-MM-dd") &&
                              format(event.start_time, "yyyy-MM-dd") !==
                                format(day, "yyyy-MM-dd")
                                ? 24 * 50 - 2
                                : format(event.start_time, "yyyy-MM-dd") !==
                                  format(day, "yyyy-MM-dd")
                                ? ((event.end_time - day.setHours(0, 0, 0)) /
                                    (1000 * 60 * 60)) *
                                    50 -
                                  2
                                : format(event.end_time, "yyyy-MM-dd") !==
                                  format(day, "yyyy-MM-dd")
                                ? ((day.setHours(23, 59, 59) -
                                    event.start_time +
                                    1000) /
                                    (1000 * 60 * 60)) *
                                    50 -
                                  2
                                : ((event.end_time - event.start_time) /
                                    (1000 * 60 * 60)) *
                                    50 -
                                  2
                            }px`,
                            width: `calc(${100 / n}% - 2px)`, // 幅を1/nに分割
                            left: `calc(${(eventIndex / n) * 100}% + 1px)`, // イベントの位置を調整
                            bgcolor: event.color_type,
                            color: "text.white",
                            borderRadius: 1,
                            cursor: "pointer",
                            padding: "2px 3px",
                            margin: "0px auto",
                            fontSize: "10px",
                            fontWeight: "bold",
                            transform: "translateX(0)",
                            overflow: "hidden", // ボックスの横幅を超える部分を隠す
                            zIndex: 3,
                          }}
                        >
                          {event.title}
                        </Box>
                      );
                    })}
                </Box>
              </>
            ))}
          </Grid>

          <Grid item xs={1} sx={{ position: "relative", height: "100%" }}>
            {times.map((time) => (
              <Box
                key={time}
                sx={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "0%",
                    width: "100%",
                    height: "50%",
                    borderBottom: "1px solid lightgrey",
                  }}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        onClick={handleNewEventClick}
        sx={{
          position: "fixed",
          bottom: 70,
          right: 16,
          borderRadius: "50px",
          backgroundColor: "primary.main",
          color: "text.white",
          zIndex: 4,
          boxShadow: 0,
          py: 1,
          fontWeight: "bold",
        }}
      >
        ＋ 予定を追加
      </Button>
    </Box>
  );
};

export default MyCalendar;
