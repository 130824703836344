import { parseISO } from "date-fns";
import axios from "axios";
import { API_URL } from "../../shared/constant";

const getExpenseDetail = async (url, id) => {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
  try {
    const response = await axiosInstance.get(
      `${API_URL.EXPENSE}/detail/${url}?expense_id=${id}`
    );
    const result = response.data;
    result.expense.total_amount = parseInt(result.expense.total_amount);
    console.log(result);
    const sortedChatsData = response.data.chat.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
    return {
      ...result,
      expense: result.expense,
      chat: sortedChatsData,
    };
  } catch (error) {
    console.error("Error fetching schedule detail:", error);
    return null;
  }
};

export default getExpenseDetail;
