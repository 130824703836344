import axios from "axios";
import { API_URL } from "../../shared/constant";

// スケジュールの更新関数
const deleteSchedule = async (url, id) => {
  try {
    const response = await axios.delete(
      `${API_URL.SCHEDULE}/${url}?schedule_id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("スケジュールの更新に失敗しました:", error);
    throw error;
  }
};

export default deleteSchedule;
