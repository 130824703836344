import axios from "axios";
import { API_URL } from "../../shared/constant";

const createNewItinerary = async (body) => {
  console.log(body);
  try {
    const response = await axios.post(`${API_URL.ITINERARY}`, body, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    console.error("Error fetching schedules:", error);
    return error;
  }
};

export default createNewItinerary;
