import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  Grid,
  CircularProgress,
  Avatar,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NoteIcon from "@mui/icons-material/Note";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import getItn from "../../Api/group/getItn"; // サンプルデータ取得関数をインポート
import { useNavigate, useParams } from "react-router-dom";
import updateItinerary from "../../Api/itineraries/updateItinerary";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonIcon from "@mui/icons-material/Person";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { format, set } from "date-fns"; // 日付フォーマット用
import { BOX_SHADOW } from "../../shared/constant";

const Group = ({ setUrl, itineraryInfo, setItineraryInfo, setDialogOpen }) => {
  const [isEditing, setIsEditing] = useState(false); // 編集モードの管理
  const [users, setUsers] = useState(null); // 参加者情報を`name`で管理
  const [originalGroupInfo, setOriginalGroupInfo] = useState(null);
  const [originalUsers, setOriginalUsers] = useState(null);
  const [groupInfo, setGroupInfo] = useState({
    title: "",
    startDate: "",
    endDate: "",
    totalBudget: null,
  });
  const { url } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const [allItineraryList, setAllItineraryList] = useState({}); // オブジェクトに変更

  // データを取得して表示
  useEffect(() => {
    if (url) {
      setUrl(url);
      let url_list = localStorage.getItem("url_list")?.split(",");
      console.log(url_list);
      if (url_list && url_list.length > 0) {
        if (!url_list.includes(url)) {
          url_list.push(url);
          localStorage.setItem("url_list", [...url_list]);
        }
      } else {
        url_list = [url];
        localStorage.setItem("url_list", url_list);
      }
      console.log(url_list);
      const url_list_without_current = url_list.filter(
        (url_tmp) => url_tmp !== url
      );
      console.log("対象", url_list_without_current);
      // useEffect内の該当部分を以下のように修正
      url_list_without_current?.map((url_now, index) => {
        getItn(url_now).then((data) => {
          if (data) {
            setAllItineraryList((prev) => ({
              ...prev,
              [url_now]: {
                // URLをキーとして使用
                url: url_now,
                title: data.title,
                startDate: data.start_date,
                endDate: data.end_date,
              },
            }));
          }
        });
      });
    } else {
      navigate(`/`);
    }
    console.log(itineraryInfo);
    if (!itineraryInfo || Object.keys(itineraryInfo).length === 0) {
      getItn(url).then((data) => {
        if (!data) {
          navigate("/");
          return;
        }
        setGroupInfo({
          title: data.title,
          startDate: data.start_date,
          endDate: data.end_date,
          totalBudget: data.total_budget,
        });
        setUsers(data.users); // 参加者情報をセット
        setItineraryInfo(data);
      }); // データを取得
    } else {
      setGroupInfo({
        title: itineraryInfo.title,
        startDate: itineraryInfo.start_date,
        endDate: itineraryInfo.end_date,
        totalBudget: itineraryInfo.total_budget,
      });
      setUsers(itineraryInfo.users); // 参加者情報をセット
    }
  }, [url, itineraryInfo]);

  const handleAddUser = () => {
    setUsers([...users, { member_id: -99, name: "" }]);
  };

  const handleChangeUser = (index, value) => {
    const newUsers = [...users];
    newUsers[index].name = value;
    setUsers(newUsers);
  };

  // 日付のフォーマット関数
  const formatDateRange = (startDate, endDate) => {
    const formattedStart = format(new Date(startDate), "MM/dd");
    const formattedEnd = format(new Date(endDate), "MM/dd");
    return `${formattedStart} ~ ${formattedEnd}`;
  };

  const handleDeleteUser = (index) => {
    const newUsers = users.filter((_, i) => i !== index);
    setUsers(newUsers);
  };

  // 予算のフォーマット関数（カンマ区切り）
  const formatBudget = (budget) => {
    return new Intl.NumberFormat("ja-JP").format(budget);
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // バリデーションエラーがある場合は送信しない
    }

    setIsSaving(true); // 保存処理開始

    try {
      const saveBody = {
        title: groupInfo.title,
        start_date: groupInfo.startDate,
        end_date: groupInfo.endDate,
        total_budget: String(groupInfo.totalBudget)?.replace(/[^0-9]/g, ""),
        members: users.map((user) => ({
          member_id: user.member_id,
          name: user.name,
        })),
      };

      const newMembers = await updateItinerary(url, saveBody);
      setItineraryInfo((prev) => ({
        ...prev,
        title: groupInfo.title,
        start_date: groupInfo.startDate,
        end_date: groupInfo.endDate,
        total_budget: String(groupInfo.totalBudget)?.replace(/[^0-9]/g, ""),
        users: newMembers,
      }));

      // 編集完了時にユーザーがリストに存在しない場合、ダイアログを開く
      const loginUserId = localStorage.getItem("loginUserId");
      if (!newMembers.some((user) => user.member_id == loginUserId)) {
        setDialogOpen(true);
      }

      setIsEditing(false); // 編集モード解除
    } catch (error) {
      if (
        error.response &&
        error.response.data.error_type === "ForeignKeyViolation"
      ) {
        setErrorSnackbarOpen(true);
        setTimeout(() => {
          setErrorSnackbarOpen(false);
        }, 4000);
      }
      console.error("Error saving itinerary:", error);
    } finally {
      setIsSaving(false); // 保存処理終了
    }
  };

  const handleEditToggle = () => {
    if (!isEditing) {
      // 編集モード開始時に現在のデータを保存
      setOriginalGroupInfo({ ...groupInfo });
      setOriginalUsers([...users]);
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (field, value) => {
    setGroupInfo({ ...groupInfo, [field]: value });
  };

  const handleCancelEdit = () => {
    // 編集前の状態に戻す
    setGroupInfo(originalGroupInfo);
    setUsers(originalUsers);

    // 編集モードを解除
    setIsEditing(false);
  };

  const validateForm = () => {
    const newErrors = {};

    // タイトルのバリデーション
    if (!groupInfo.title.trim()) {
      newErrors.title = "タイトルは必須です。";
    }

    // 開始日と終了日のバリデーション
    if (!groupInfo.startDate) {
      newErrors.startDate = "開始日は必須です。";
    }

    if (!groupInfo.endDate) {
      newErrors.endDate = "終了日は必須です。";
    }

    if (groupInfo.startDate && groupInfo.endDate) {
      const start = new Date(groupInfo.startDate);
      const end = new Date(groupInfo.endDate);
      if (start > end) {
        newErrors.endDate = "終了日は開始日より後である必要があります。";
      }
    }

    // メンバーのバリデーション
    const validUsers = users.filter((user) => user.name.trim() !== "");
    if (validUsers.length === 0) {
      newErrors.users = "メンバーは1人以上必要です。";
    } else if (validUsers.length !== users.length) {
      newErrors.users = "全てのメンバー名を入力してください。";
    }

    // 合計予算のバリデーション
    if (groupInfo.totalBudget && parseInt(groupInfo.totalBudget, 10) < 0) {
      newErrors.totalBudget = "合計予算は0以上の数値を入力してください。";
    }

    setErrors(newErrors);

    // エラーがない場合のみtrueを返す
    return Object.keys(newErrors).length === 0;
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (!users || !groupInfo) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 600, margin: "0 auto" }}>
      {/* タイトル */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6">しおりの情報</Typography>

        {isEditing && (
          <Box sx={{ display: "flex" }}>
            {/* 保存ボタン */}
            {isSaving ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  borderRadius: "50px",
                  paddingX: 3,
                  backgroundColor: "primary.main",
                  color: "text.white",
                }}
              >
                保存
              </Button>
            )}
            {/* キャンセルボタン */}
            <IconButton onClick={handleCancelEdit} sx={{ ml: 1 }}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {/* グループ情報の表示 */}
      {!isEditing && (
        <Box
          sx={{
            backgroundColor: "primary.bg",
            padding: "16px",
            borderRadius: "16px",
            boxShadow: BOX_SHADOW,
            fontSize: "18px",
            lineHeight: "1.6",
            position: "relative", // アイコンの配置用
          }}
        >
          {/* 編集アイコン */}
          {!isEditing && (
            <IconButton
              onClick={handleEditToggle}
              sx={{ position: "absolute", top: "8px", right: "8px" }}
            >
              <EditIcon />
            </IconButton>
          )}

          {/* タイトル */}
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: "8px" }}>
            {groupInfo.title}
          </Typography>

          {/* 日付範囲 */}
          <Typography sx={{ mb: 1, mt: 2 }}>
            <CalendarTodayIcon
              sx={{ verticalAlign: "text-bottom", mr: 2 }}
              onClick={() => {
                navigate(`/calendar/${url}`);
              }}
            />
            日程：{formatDateRange(groupInfo.startDate, groupInfo.endDate)}
          </Typography>

          {/* 合計予算 */}
          <Typography>
            <AttachMoneyIcon
              sx={{ verticalAlign: "text-bottom", mr: 2 }}
              onClick={() => {
                navigate(`/accounting/${url}`);
              }}
            />
            合計予算：
            {groupInfo.totalBudget
              ? formatBudget(groupInfo.totalBudget)
              : "--- "}
            円
          </Typography>

          {/* ユーザーリスト */}
          <Typography variant="h6" sx={{ mt: "16px", mb: "8px" }}>
            参加者
          </Typography>

          <Grid container spacing={2}>
            {users?.map((user, index) => (
              <Grid item xs={4} key={index} sx={{ textAlign: "center" }}>
                {/* アバターアイコン */}
                <Avatar
                  sx={{
                    margin: "0 auto",
                    bgcolor:
                      localStorage.getItem("loginUserId") == user.member_id
                        ? "primary.main"
                        : "others.nonActiveUserIcon",
                  }}
                >
                  <PersonIcon />
                </Avatar>
                {/* ユーザー名 */}
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {user.name}
                  <br />
                  {localStorage.getItem("loginUserId") == user.member_id &&
                    "(あなた)"}
                </Typography>
              </Grid>
            ))}
          </Grid>

          {/* ユーザ変更ダイアログハンドラー */}
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <IconButton onClick={setDialogOpen}>
              <PeopleAltIcon />
            </IconButton>
            <Typography
              variant="body2"
              onClick={setDialogOpen}
              sx={{ color: "text.main", fontWeight: "bold" }}
            >
              自分を選び直す
            </Typography>
          </Box>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100%",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                handleCopyUrl();
              }}
              sx={{
                borderRadius: "30px",
                backgroundColor: "primary.main",
                color: "text.white",
                fontWeight: "bold",
                boxShadow: BOX_SHADOW,
                fontSize: "1rem",
                py: 1,
                px: 4,
                mt: 2,
                zIndex: 1,
                minWidth: "98%",
              }}
            >
              <ContentCopyIcon sx={{ verticalAlign: "text-bottom", mr: 1 }} />
              旅のURLを共有しよう！
            </Button>
          </Box>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100%",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/calendar/${url}`);
              }}
              sx={{
                borderRadius: "30px",
                backgroundColor: "primary.main",
                color: "text.white",
                fontWeight: "bold",
                boxShadow: BOX_SHADOW,
                fontSize: "1rem",
                py: 1,
                px: 4,
                mt: 2,
                zIndex: 1,
                minWidth: "98%",
              }}
            >
              <CalendarTodayIcon sx={{ verticalAlign: "text-bottom", mr: 1 }} />
              旅の予定を作成
              <ArrowForwardIosIcon
                sx={{ verticalAlign: "text-bottom", ml: 1, fontSize: "1.2rem" }}
              />
            </Button>
          </Box>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100%",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/accounting/${url}`);
              }}
              sx={{
                borderRadius: "30px",
                backgroundColor: "primary.main",
                color: "text.white",
                fontWeight: "bold",
                boxShadow: BOX_SHADOW,
                fontSize: "1rem",
                py: 1,
                px: 4,
                mt: 2,
                zIndex: 1,
                minWidth: "98%",
              }}
            >
              <AttachMoneyIcon sx={{ verticalAlign: "text-bottom", mr: 1 }} />
              支払いを記録
              <ArrowForwardIosIcon
                sx={{ verticalAlign: "text-bottom", ml: 1, fontSize: "1.2rem" }}
              />
            </Button>
          </Box>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100%",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/notes/${url}`);
              }}
              sx={{
                borderRadius: "30px",
                backgroundColor: "primary.main",
                color: "text.white",
                fontWeight: "bold",
                boxShadow: BOX_SHADOW,
                fontSize: "1rem",
                py: 1,
                px: 4,
                mt: 2,
                zIndex: 1,
                minWidth: "98%",
              }}
            >
              <NoteIcon sx={{ verticalAlign: "text-bottom", mr: 1 }} />
              旅のノートを作成
              <ArrowForwardIosIcon
                sx={{ verticalAlign: "text-bottom", ml: 1, fontSize: "1.2rem" }}
              />
            </Button>
          </Box>
        </Box>
      )}
      {/* その他のしおり一覧 */}
      {!isEditing && (
        <>
          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            その他のしおり
          </Typography>
          {Object.values(allItineraryList)?.map((itineraryInfo, index) => (
            <Box
              key={itineraryInfo.url}
              onClick={() => {
                navigate(`/group/${itineraryInfo.url}`);
                window.location.reload();
              }}
              sx={{
                backgroundColor: "primary.bg",
                padding: "16px",
                borderRadius: "16px",
                boxShadow: BOX_SHADOW,
                mb: 2,
                cursor: "pointer",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                transition: "background-color 0.3s",
              }}
            >
              <Box sx={{ flex: 1, mr: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  {itineraryInfo.title}
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  {formatDateRange(
                    itineraryInfo.startDate,
                    itineraryInfo.endDate
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: "50%",
                  width: 32,
                  height: 32,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForwardIosIcon sx={{ color: "white", fontSize: 18 }} />
              </Box>
            </Box>
          ))}
        </>
      )}
      {!isEditing && (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              navigate("/");
              window.location.reload();
            }}
            sx={{
              borderRadius: "50px",
              backgroundColor: "primary.main",
              color: "text.white",
              fontWeight: "bold",
              boxShadow: BOX_SHADOW,
              fontSize: "1.2rem",
              py: 2,
              px: 4,
              mt: 2,
              mb: 8,
              zIndex: 1,
            }}
          >
            ＋ 新規しおりを作成
          </Button>
        </Box>
      )}
      {/* 編集モードでの入力ボックス */}
      {isEditing && (
        <>
          {/* グループ情報の入力ボックス */}
          <Grid container spacing={2}>
            {/* タイトル入力ボックス */}
            <Grid item xs={12}>
              <TextField
                label="タイトル"
                fullWidth
                value={groupInfo.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
                error={!!errors.title} // エラーがあるかどうか
                helperText={errors.title} // エラーメッセージ表示
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="開始日"
                type="date"
                fullWidth
                value={groupInfo.startDate}
                onChange={(e) => handleInputChange("startDate", e.target.value)}
                error={!!errors.startDate}
                helperText={errors.startDate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="終了日"
                type="date"
                fullWidth
                value={groupInfo.endDate}
                onChange={(e) => handleInputChange("endDate", e.target.value)}
                error={!!errors.endDate}
                helperText={errors.endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="合計予算"
                type="number"
                fullWidth
                value={groupInfo.totalBudget || null}
                onChange={(e) =>
                  handleInputChange("totalBudget", e.target.value)
                }
                error={!!errors.totalBudget}
                helperText={errors.totalBudget}
              />
            </Grid>
          </Grid>

          {/* ユーザー編集ボックス */}
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
            参加者
          </Typography>
          {users?.map((user, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <TextField
                fullWidth
                label={`参加者 ${index + 1}`}
                value={user.name}
                onChange={(e) => handleChangeUser(index, e.target.value)}
                error={!!errors.users}
                helperText={index === 0 ? errors.users : ""}
              />
              {users.length > 1 && (
                <IconButton
                  onClick={() => handleDeleteUser(index)}
                  sx={{ ml: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
          <IconButton onClick={handleAddUser} sx={{ mb: 2 }}>
            <AddIcon />
          </IconButton>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="URLをコピーしました。"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        ContentProps={{
          sx: {
            backgroundColor: "primary.main",
          },
        }}
      />
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={5000} // 5秒後に自動的に閉じる
        onClose={() => {
          setErrorSnackbarOpen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // 画面上部中央に表示
      >
        <Alert
          onClose={() => {
            setErrorSnackbarOpen(false);
          }}
          severity="error"
        >
          削除対象のユーザはすでにしおりの記録に関与しているため削除できません。
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Group;
