export const hexToRgba = (hex, alpha = 0.1) => {
  // #を除去して6桁を確認
  const cleanHex = hex.replace("#", "");
  if (cleanHex.length !== 6) {
    throw new Error("Invalid hex color format. Use #RRGGBB format.");
  }

  // 赤、緑、青を取得
  const r = parseInt(cleanHex.slice(0, 2), 16);
  const g = parseInt(cleanHex.slice(2, 4), 16);
  const b = parseInt(cleanHex.slice(4, 6), 16);

  // rgba文字列を返す
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const API_URL = {
  ITINERARY: process.env.REACT_APP_ITINERARY_URL,
  SCHEDULE: process.env.REACT_APP_SCHEDULE_URL,
  EXPENSE: process.env.REACT_APP_EXPENSE_URL,
  NOTE: process.env.REACT_APP_NOTE_URL,
  THREAD: process.env.REACT_APP_THREAD_URL,
};

export const COLOR_THEME = {
  palette: {
    primary: {
      main: "#fa983a",
      bg: "#f7ecdc",
      currentLine: "#e05e3b",
    },
    text: {
      primary: "#553311",
      white: "#ffffff",
      active: "#009A9A",
      highlight: "#fdf5e6",
      grey: "grey",
    },
    eventBox: {
      a: "#e55039",
      b: "#4a69bd",
      c: "#60a3bc",
      d: "#b71540",
    },
    graph: {
      budget: "#009A9A",
      amount: "#E09AA8",
      individual: "#D98E00",
    },
    others: {
      toggleGreyBg: "#eeeeee",
      toggleGreyText: "#444444",
      toggleGreyBorder: "#dddddd",
      eventMemoGreyBg: "#f9f9f9",
      nonActiveUserIcon: "#ffcc80",
    },
  },
};

export const COLOR_MAP = [
  { name: "a", code: COLOR_THEME.palette.eventBox.a, id: "01" },
  { name: "b", code: COLOR_THEME.palette.eventBox.b, id: "02" },
  { name: "c", code: COLOR_THEME.palette.eventBox.c, id: "03" },
  { name: "d", code: COLOR_THEME.palette.eventBox.d, id: "04" },
];

export const BOX_SHADOW = "0px 1px 3px 0px rgba(0, 0, 0, 0.12);";
