import axios from "axios";
import { API_URL } from "../../shared/constant";

// チャットの追加（サンプルデータに追加するだけ）
export const addChat = async (url, threadId, content, member_id) => {
  try {
    const response = await axios.post(
      `${API_URL.THREAD}/chat/${url}?thread_id=${threadId}`,
      {
        content: content,
        member_id: member_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    const chatsData = response.data.chat;
    const sortedChatsData = chatsData.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
    return sortedChatsData;
  } catch (error) {
    console.error("リアクションの追加に失敗しました:", error);
    throw error;
  }
};
