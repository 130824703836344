import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Grid,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Avatar,
  Link,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  isAfter,
  isBefore,
  startOfDay,
  endOfDay,
  isWithinInterval,
} from "date-fns";
import { ja } from "date-fns/locale"; // 日本語ロケールをインポート
import ColorLensIcon from "@mui/icons-material/ColorLens";
import EditIcon from "@mui/icons-material/Edit";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import ThreadComponent from "../../shared/ThreadComponent";
import updateSchedule from "../../Api/schedules/updateSchedule";
import createSchedule from "../../Api/schedules/createSchedule";
import getScheduleDetail from "../../Api/schedules/getScheduleDetail";
import deleteSchedule from "../../Api/schedules/deleteSchedule";
import getItn from "../../Api/group/getItn";
import {
  LocalizationProvider,
  DatePicker,
  DesktopTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  BOX_SHADOW,
  COLOR_MAP,
  COLOR_THEME,
  hexToRgba,
} from "../../shared/constant";

import { format } from "date-fns";
import { parseContentWithUrls } from "../../shared/utils";
import "../../styles/main.css";

// JSTに変換する関数
const convertToJST = (date) => {
  const jstOffset = 9 * 60 * 60 * 1000;
  const jstDate = new Date(date.getTime() + jstOffset);
  return jstDate.toISOString().slice(0, 16);
};

// JSTに戻す関数 (保存時に使用)
const convertFromJST = (dateString) => {
  const date = new Date(dateString);
  const jstOffset = 9 * 60 * 60 * 1000;
  // return new Date(date.getTime() - jstOffset);
  return new Date(date.getTime() + jstOffset);
};

const EventDetail = ({ setUrl, itineraryInfo, setItineraryInfo }) => {
  const { url } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id"); // 新規作成時はnull, 編集時はschedule_idが入る。
  const new_date = searchParams.get("date");

  const navigate = useNavigate();

  const [eventData, setEventData] = useState(null);
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [estimated_cost, setEstimatedCost] = useState(null);
  const [actual_cost, setCost] = useState(null);
  const [link, setLocationField] = useState("");
  const [memo, setMemo] = useState("");
  const [colorType, setColorType] = useState("01");
  const [scheduleType, setScheduleType] = useState("01");
  const [threadId, setThreadId] = useState(null);

  const [reactions, setReactions] = useState([]);
  const [chats, setChats] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [errors, setErrors] = useState({});

  const [isSaving, setIsSaving] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isEditing, setIsEditing] = useState(false);

  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  const [isAbleToSave, setIsAbleToSave] = useState(false);

  // イベントデータを取得する関数
  const fetchEventData = async () => {
    try {
      if (Object.keys(itineraryInfo).length === 0) {
        getItn(url).then((data) => {
          if (!data) {
            navigate("/");
            return;
          }
          setItineraryInfo(data);
        });
      }
      const fetchInfo = await getScheduleDetail(url, id, false);
      const event = fetchInfo.schedule;

      if (event) {
        setEventData(event);
        setTitle(event.title || "");
        setStartTime(
          event.start_time ? new Date(convertToJST(event.start_time)) : ""
        );
        setEndTime(
          event.end_time ? new Date(convertToJST(event.end_time)) : ""
        );
        setStartDate(
          event.start_time ? new Date(convertToJST(event.start_time)) : ""
        );
        setEndDate(
          event.end_time ? new Date(convertToJST(event.end_time)) : ""
        );
        setEstimatedCost(event.estimated_cost || null);
        setCost(event.actual_cost || null);
        setLocationField(event.link || "");
        setMemo(event.memo || "");
        setColorType(event.color_type || "01");
        setScheduleType(event.schedule_type || "01");
        setThreadId(event.thread_id);
      }
      if (fetchInfo.reaction && fetchInfo.reaction.length > 0) {
        setReactions(fetchInfo.reaction);
      }
      if (fetchInfo.chat && fetchInfo.chat.length > 0) {
        setChats(fetchInfo.chat);
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
      setErrorMessage(error.response.data.error);
      setOpenSnack(true);
    }
  };

  // 新しいイベントデータを設定する関数
  const setNewEventData = async () => {
    let data;
    if (Object.keys(itineraryInfo).length === 0) {
      data = await getItn(url);
      if (!data) {
        navigate("/");
        return;
      }
      setItineraryInfo(data);
    } else {
      data = itineraryInfo;
    }
    let start_time;
    let end_time;
    console.log(new Date(parseInt(new_date)));
    if (new_date) {
      start_time = new Date(parseInt(new_date));
      end_time = new Date(
        new Date(parseInt(new_date)).getTime() + 60 * 60 * 1000
      );
    } else {
      const now = new Date();
      now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15, 0, 0);
      const startDate = new Date(data.start_date);
      const endDate = new Date(data.end_date);
      endDate.setHours(23, 59);
      start_time = isWithinInterval(now, {
        start: startDate,
        end: endDate,
      })
        ? now
        : startDate;
      const start_time_tmp01 = new Date(start_time); // 23:00までの予定を作成
      const start_time_tmp02 = new Date(start_time); // 23:59までの予定を作成
      const start_time_tmp03 = new Date(start_time); // 23:59までの予定を作成
      start_time_tmp01.setHours(23, 0);
      start_time_tmp02.setHours(23, 59);
      start_time_tmp03.setHours(23, 45);
      console.log(start_time_tmp01, start_time_tmp02);
      end_time = isWithinInterval(start_time, {
        start: start_time_tmp01,
        end: start_time_tmp02,
      })
        ? start_time_tmp03 // 23:45までの予定を作成
        : new Date(start_time.getTime() + 60 * 60 * 1000); // startDateに1時間追加
    }
    const event = {
      title: "",
      start_time: start_time,
      end_time: end_time,
      estimated_cost: null,
      actual_cost: null,
      link: "",
      memo: "",
      schedule_type: "01",
      color_type: "01",
    };
    setTitle(event.title || "");
    setStartTime(event.start_time);
    setEndTime(event.end_time);
    setStartDate(event.start_time);
    setEndDate(event.end_time);
    setEstimatedCost(event.estimated_cost);
    setCost(event.actual_cost);
    setLocationField(event.link || "");
    setMemo(event.memo || "");
    setColorType(event.color_type || "01");
    setScheduleType(event.schedule_type || "01");
    setEventData(event);
  };

  // フォームのバリデーションを行う関数
  const validateForm = () => {
    const newErrors = {};

    // タイトルのバリデーション
    if (!title.trim()) {
      newErrors.title = "タイトルは必須です";
    }

    // 日時のバリデーション
    const startDateTime = new Date(startDate);
    startDateTime.setHours(startTime.getHours(), startTime.getMinutes());
    const endDateTime = new Date(endDate);
    endDateTime.setHours(endTime.getHours(), endTime.getMinutes());

    if (isAfter(startDateTime, endDateTime)) {
      newErrors.dateTime = "開始日時は終了日時より前である必要があります";
    }

    // 日付範囲のバリデーション
    const itineraryStartDate = startOfDay(new Date(itineraryInfo.start_date));
    const itineraryEndDate = endOfDay(new Date(itineraryInfo.end_date));

    if (
      isBefore(startDateTime, itineraryStartDate) ||
      isAfter(startDateTime, itineraryEndDate)
    ) {
      newErrors.startDate = "開始日は旅程の範囲内である必要があります";
    }

    if (
      isBefore(endDateTime, itineraryStartDate) ||
      isAfter(endDateTime, itineraryEndDate)
    ) {
      newErrors.endDate = "終了日は旅程の範囲内である必要があります";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // イベントを保存する関数
  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    const start_time = new Date(startDate);
    const end_time = new Date(endDate);
    if (scheduleType === "01") {
      start_time.setHours(startTime.getHours(), startTime.getMinutes());
      end_time.setHours(endTime.getHours(), endTime.getMinutes());
    } else {
      start_time.setHours(0, 0);
      end_time.setHours(23, 0);
    }
    let new_estimated_cost = estimated_cost;
    if (estimated_cost && isNaN(Number(estimated_cost))) {
      const new_cost = String(estimated_cost)?.replace(/[^0-9]/g, "");
      console.log("estimated_cost", new_cost);
      new_estimated_cost = parseInt(new_cost);
    }
    let updatedEvent = {
      ...eventData,
      title,
      start_time: convertFromJST(start_time),
      end_time: convertFromJST(end_time),
      estimated_cost: new_estimated_cost,
      link: link,
      memo: memo,
      color_type: colorType,
      schedule_type: scheduleType,
      thread_type: 1,
      creator_id:
        localStorage.getItem("loginUserId") || itineraryInfo.users[0].member_id, // localStorageから取得したloginUserId
    };

    try {
      setIsSaving(true); // 保存処理開始時にtrueに設定
      if (id === null) {
        // 新規作成の場合
        const response = await createSchedule(url, updatedEvent);
        setThreadId(response.thread_id);
        console.log("保存されたイベント:", updatedEvent);
        navigate(-1);
      } else {
        // 更新の場合
        await updateSchedule(url, id, updatedEvent);
        console.log("更新されたイベント:", updatedEvent);
        navigate(-1);
      }
    } catch (err) {
      console.error("イベントの保存に失敗しました:", err);
      setErrorMessage(err.message);
      setOpenSnack(true);
    } finally {
      setIsSaving(false); // 保存処理終了時にfalseに設定
    }
  };

  // イベントを削除する関数
  const handleDelete = async () => {
    if (window.confirm("本当にこの予定を削除しますか？")) {
      setIsSaving(true);
      try {
        await deleteSchedule(url, id);
        navigate(-1);
      } catch (err) {
        console.error("予定の削除に失敗しました:", err);
        setErrorMessage(err.response.data.error);
        setOpenSnack(true);
      } finally {
        setIsSaving(false);
      }
    }
  };

  // Snackbarを閉じる処理
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  // カラーポップオーバーを開く処理
  const handleColorClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // カラーポップオーバーを閉じる処理
  const handleColorClose = () => {
    setAnchorEl(null);
  };

  // カラーを変更する処理
  const handleColorChange = (colorCode) => {
    setColorType(colorCode);
    handleColorClose();
  };

  // 開始日を変更する処理
  const startDateHandler = (newValue) => {
    const timeDifference = endDate.getTime() - startDate.getTime();
    setStartDate(newValue);
    const newEndDate = new Date(newValue.getTime() + timeDifference);
    const itineraryEndDate = new Date(itineraryInfo.end_date);
    if (newEndDate > itineraryEndDate) {
      setEndDate(itineraryEndDate);
    } else {
      setEndDate(newEndDate);
    }
  };

  // 開始時刻を変更する処理
  const startTimeHandler = (newValue) => {
    setStartTime(newValue);
    let newEndTime = new Date(endTime);
    const timeDifference = newValue.getTime() - startTime.getTime();
    setEndTime(new Date(newEndTime.getTime() + timeDifference));
  };

  const handleCopyUrl = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      setCopySnackbarOpen(true);
    });
  };

  const open = Boolean(anchorEl);
  const idPopOver = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (!url) {
      navigate("/");
      return;
    } else {
      setUrl(url);
    }

    if (id) {
      // idがある場合は編集画面
      fetchEventData();
    } else {
      setIsEditing(true);
      // idがない場合は新規作成画面
      setNewEventData();
    }
  }, [url, id]);

  useEffect(() => {
    const result = !title.trim() ? false : true;
    setIsAbleToSave(result);
  }, [title]);

  // useEffect(() => {
  //   if (!url || !id) {
  //     return;
  //   }
  //   const interval = setInterval(async () => {
  //     const fetchInfo = await getScheduleDetail(url, id, false);

  //     if (fetchInfo.reaction && fetchInfo.reaction.length > 0) {
  //       setReactions(fetchInfo.reaction);
  //     }

  //     if (fetchInfo.chat && fetchInfo.chat.length > 0) {
  //       setChats(fetchInfo.chat);
  //     }
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [url, id]);

  // スケジュールタイプに応じた日付表示
  const displayDate = () => {
    console.log(startDate, endDate, scheduleType);
    if (format(startDate, "yyyy-MM-dd") === format(endDate, "yyyy-MM-dd")) {
      return scheduleType === "01"
        ? `${format(new Date(startDate), "MM/dd(E) HH:mm", {
            locale: ja,
          })}〜${format(new Date(endDate), "HH:mm", { locale: ja })}`
        : `${format(new Date(startDate), "MM/dd(E)", { locale: ja })}`;
    } else {
      return scheduleType === "01"
        ? `${format(new Date(startDate), "MM/dd(E) HH:mm", {
            locale: ja,
          })} 〜 ${format(new Date(endDate), "MM/dd(E) HH:mm", {
            locale: ja,
          })}`
        : `${format(new Date(startDate), "MM/dd(E)", {
            locale: ja,
          })} 〜 ${format(new Date(endDate), "MM/dd(E)", {
            locale: ja,
          })}`;
    }
  };
  if (!eventData || !itineraryInfo || isSaving) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThreadComponent
      threadId={threadId}
      threadType={1}
      handleSaveClick={handleSave}
      chatsData={chats}
      reactionsData={reactions}
      users={itineraryInfo.users}
      url={url}
      setChats={setChats}
      setReactions={setReactions}
      isViewing={!isEditing} // 編集中はfalse
      setIsEditing={setIsEditing}
      isAbleToSave={isAbleToSave}
    >
      {/* Snackbarコンポーネント */}
      <Snackbar
        open={openSnack}
        autoHideDuration={5000} // 5秒後に自動的に閉じる
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // 画面上部中央に表示
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* スケジュール作成部分 */}
      {isEditing ? (
        <Box sx={{ mb: 2 }}>
          {/* ヘッダー（新規作成 or 編集で表示を変える） */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">
              予定を{id == null ? "作成" : "編集"}
            </Typography>
            {id !== null && (
              <IconButton onClick={handleDelete} sx={{ color: "text.grey" }}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>

          {/* タイトル & カラーパレット */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2 }}>
            <TextField
              autoFocus
              label="タイトル"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ mr: 1, flexGrow: 1 }}
              error={!!errors.title}
              helperText={errors.title}
            />
            <Select
              value={colorType}
              onChange={(e) => handleColorChange(e.target.value)}
              displayEmpty
              renderValue={(selected) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      bgcolor: COLOR_MAP.find((c) => c.id === selected)?.code,
                      mr: 1,
                    }}
                  />
                </Box>
              )}
              sx={{
                height: 56, // TextFieldと同じ高さに
              }}
            >
              {COLOR_MAP.map((color) => (
                <MenuItem key={color.id} value={color.id}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        bgcolor: color.code,
                        mr: 1,
                      }}
                    />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* schedule_type 切り替えのトグルボタン */}
          <ToggleButtonGroup
            value={scheduleType}
            exclusive
            sx={{
              display: "flex",
              justifyContent: "center", // 中央に配置
              mb: 2,
              width: "100%", // ボタンの幅を全体に広げる
            }}
            onChange={(event, newType) => {
              if (newType !== null) {
                setScheduleType(newType); // 状態更新は確実に行う
              }
            }}
          >
            <ToggleButton
              value="01"
              sx={{
                borderRadius: "12px", // 丸角のボタン
                width: "50%", // ボタンの横幅を均等に
                "&.Mui-selected": {
                  backgroundColor: "primary.main", // 選択かつホバー時も同じ背景色にする
                  fontWeight: "bold",
                  borderColor: "primary.main",
                  color: "text.white", // 選択時の文字色
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "primary.main", // 選択かつホバー時も同じ背景色にする
                  fontWeight: "bold",
                  borderColor: "primary.main",
                  color: "text.white", // 選択時の文字色
                },
                borderColor: "others.toggleGreyBorder",
                backgroundColor: "others.toggleGreyBg", // 非選択時の背景色
                color: "others.toggleGreyText", // 非選択時の文字色
                "&:hover": {
                  borderColor: "others.toggleGreyBorder",
                  backgroundColor: "others.toggleGreyBg", // 非選択時の背景色
                  color: "others.toggleGreyText", // 非選択時の文字色
                },
              }}
            >
              通常予定
            </ToggleButton>
            <ToggleButton
              value="02"
              sx={{
                borderRadius: "12px", // 丸角のボタン
                width: "50%", // ボタンの横幅を均等に
                "&.Mui-selected": {
                  backgroundColor: "primary.main", // 選択かつホバー時も同じ背景色にする
                  fontWeight: "bold",
                  borderColor: "primary.main",
                  color: "text.white", // 選択時の文字色
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "primary.main", // 選択かつホバー時も同じ背景色にする
                  fontWeight: "bold",
                  borderColor: "primary.main",
                  color: "text.white", // 選択時の文字色
                },
                borderColor: "others.toggleGreyBorder",
                backgroundColor: "others.toggleGreyBg", // 非選択時の背景色
                color: "others.toggleGreyText", // 非選択時の文字色
                "&:hover": {
                  borderColor: "others.toggleGreyBorder",
                  backgroundColor: "others.toggleGreyBg", // 非選択時の背景色
                  color: "others.toggleGreyText", // 非選択時の文字色
                },
              }}
            >
              終日予定
            </ToggleButton>
          </ToggleButtonGroup>

          {scheduleType === "01" ? (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <DatePicker
                    label="開始日"
                    value={startDate}
                    onChange={(newValue) => startDateHandler(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    format="MM/dd" // 日本式の日付フォーマット
                    slotProps={{
                      toolbar: { hidden: true },
                      calendarHeader: { format: "yyyy/MM" },
                      textField: {
                        error: !!errors.startDate,
                        helperText: errors.startDate,
                      },
                    }}
                    minDate={new Date(itineraryInfo.start_date)}
                    maxDate={new Date(itineraryInfo.end_date)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DesktopTimePicker
                    label="開始時刻"
                    value={startTime}
                    onChange={(newValue) => startTimeHandler(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    minutesStep={5}
                    ampm={false}
                    views={["hours", "minutes"]}
                    format="HH:mm"
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="終了日"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    format="MM/dd" // 日本式の日付フォーマット
                    slotProps={{
                      toolbar: { hidden: true },
                      calendarHeader: { format: "yyyy/MM" },
                      textField: {
                        error: !!errors.endDate,
                        helperText: errors.endDate,
                      },
                    }}
                    minDate={startDate}
                    maxDate={new Date(itineraryInfo.end_date)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DesktopTimePicker
                    label="終了時刻"
                    value={endTime}
                    onChange={(newValue) => setEndTime(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    minutesStep={5}
                    ampm={false}
                    views={["hours", "minutes"]}
                    format="HH:mm"
                    minTime={
                      format(startDate, "yyyy-MM-dd") ==
                        format(endDate, "yyyy-MM-dd") &&
                      new Date(startTime.getTime() + 30 * 60 * 1000)
                    }
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <DatePicker
                    label="開始日"
                    value={startDate}
                    onChange={(newValue) => startDateHandler(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    format="MM/dd" // 日本式の日付フォーマット
                    slotProps={{
                      toolbar: { hidden: true },
                      calendarHeader: { format: "yyyy/MM" },
                      textField: {
                        error: !!errors.startDate,
                        helperText: errors.startDate,
                      },
                    }}
                    minDate={new Date(itineraryInfo.start_date)}
                    maxDate={new Date(itineraryInfo.end_date)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="終了日"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    format="MM/dd" // 日本式の日付フォーマット
                    slotProps={{
                      toolbar: { hidden: true },
                      calendarHeader: { format: "yyyy/MM" },
                      textField: {
                        error: !!errors.endDate,
                        helperText: errors.endDate,
                      },
                    }}
                    minDate={new Date(startDate)}
                    maxDate={new Date(itineraryInfo.end_date)}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          )}

          <TextField
            label="メモ（任意）"
            fullWidth
            variant="outlined"
            multiline
            minRows={4}
            maxRows={10}
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            sx={{ mb: 2, "& textarea": { resize: "none" } }}
          />
          <TextField
            label="Mapなどのリンク（任意）"
            fullWidth
            value={link}
            onChange={(e) => setLocationField(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="予算（任意）"
            fullWidth
            value={estimated_cost}
            onChange={(e) => setEstimatedCost(e.target.value)}
            sx={{ mb: 2 }}
          />
        </Box>
      ) : (
        <>
          <Card
            sx={{
              mb: 2,
              border: `2px solid ${
                COLOR_MAP.find((color) => color.id === colorType)?.code ||
                "#FFFFFF"
              }`,
              borderRadius: "16px",
              position: "relative",
              bgcolor: hexToRgba(
                COLOR_MAP.find((color) => color.id === colorType)?.code
              ),
            }}
          >
            {/* 編集アイコン */}
            <IconButton
              sx={{ position: "absolute", top: "8px", right: "8px" }}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <EditIcon />
            </IconButton>

            <CardContent>
              {/* タイトル */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  color: `${
                    COLOR_MAP.find((color) => color.id === colorType)?.code ||
                    "#FFFFFF"
                  }`,
                }}
              >
                {title}
              </Typography>

              {/* 日付表示 */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CalendarTodayIcon />
                <Typography sx={{ fontSize: "1.2rem", ml: 0.5 }}>
                  {displayDate()}
                </Typography>
              </Box>

              {/* 予算と実際の費用 */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <AttachMoneyIcon />
                <Typography variant="body1" sx={{ mt: 0.5, ml: 0.5 }}>
                  {estimated_cost
                    ? `予算： ${estimated_cost.toLocaleString()}円　/　`
                    : "予算： --- 円　/　"}
                  {actual_cost
                    ? `実費： ${actual_cost.toLocaleString()}円`
                    : "実費： --- 円"}
                </Typography>
              </Box>

              {/* 関連リンク */}
              {link && link != "" ? (
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <ContentCopyIcon
                    onClick={() => {
                      handleCopyUrl(link);
                    }}
                  />
                  <Link
                    href={link}
                    target="_blank"
                    rel="noopener"
                    underline="hover"
                    sx={{ mt: 0.5, ml: 0.5 }}
                  >
                    {link.length > 30 ? `${link.slice(0, 30)}...` : link}
                  </Link>
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <ContentCopyIcon sx={{ color: "text.gray" }} />
                  <Typography variant="body1" sx={{ mt: 0.5, ml: 0.5 }}>
                    リンクなし
                  </Typography>
                </Box>
              )}

              {/* メモ */}
              <Box sx={{ mt: 2 }}>
                {/* メモ用のカード */}
                <Card
                  sx={{
                    backgroundColor: "others.eventMemoGreyBg",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: BOX_SHADOW,
                    borderLeft: `8px solid ${
                      COLOR_MAP.find((color) => color.id === colorType)?.code
                    }`, // メモ部分に目立つ左枠線を追加（色は好みに応じて変更）
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    メモ
                  </Typography>
                  {memo && memo != "" ? (
                    <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                      {parseContentWithUrls(memo).map((part, index) =>
                        part.type === "url" ? (
                          <a
                            key={index}
                            href={part.content}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="note-card-a"
                          >
                            {part.content}
                          </a>
                        ) : (
                          <span key={index}>{part.content}</span>
                        )
                      )}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{ color: "text.grey", whiteSpace: "pre-wrap" }}
                    >
                      まだ記載がありません...
                      <br />
                      メモを記載しよう！
                      <br />
                      例）
                      <br />
                      ■持ち物リスト
                      <br />
                      ・歯ブラシ
                      <br />
                      ・パスポート
                      <br />
                      <br />
                      ■ホームページ
                      <br />
                      https://www.google.com
                    </Typography>
                  )}
                </Card>
              </Box>
              <Box sx={{ width: "100%", display: "flex" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/description/${url}?schedule_id=${id}`);
                  }}
                  sx={{
                    borderRadius: "25px",
                    mt: 3,
                    paddingX: 6,
                    backgroundColor: COLOR_MAP.find(
                      (color) => color.id === colorType
                    )?.code,
                    color: "text.white",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    mx: "auto",
                  }}
                >
                  <AttachMoneyIcon />
                  明細を追加
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Snackbar
            open={copySnackbarOpen}
            autoHideDuration={2000}
            onClose={() => {
              setCopySnackbarOpen(false);
            }}
            message="URLをコピーしました。"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            ContentProps={{
              sx: {
                backgroundColor: "primary.main",
              },
            }}
          />
        </>
      )}
    </ThreadComponent>
  );
};

export default EventDetail;
