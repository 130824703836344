import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import getItn from "../../Api/group/getItn";
import ThreadComponent from "../../shared/ThreadComponent";
import getExpenseDetail from "../../Api/accounting/getExpenseDetail";
import createExpense from "../../Api/accounting/createExpense";
import updateExpense from "../../Api/accounting/updateExpense";
import getAllSchedules from "../../Api/schedules/getAllSchedules"; // Import the function
import deleteExpense from "../../Api/accounting/deleteExpense";

const DescriptionDetail = ({ setUrl, itineraryInfo, setItineraryInfo }) => {
  const { url } = useParams();
  const [searchParams] = useSearchParams();
  const expense_id = searchParams.get("id");

  // Initialize split_members with all user member_ids
  const [initialSplitMembers, setInitialSplitMembers] = useState(null);

  // State for schedules
  const [schedules, setSchedules] = useState([]);

  const [descriptionData, setDescriptionData] = useState({
    title: "",
    payer_id: "",
    total_amount: "",
    schedule_id: null,
    memo: "",
    creator_id: "",
    thread_type: "2",
    split_members: [],
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [reactions, setReactions] = useState([]);
  const [chats, setChats] = useState([]);
  const [thread, setThread] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDescriptionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isAbleToSave, setIsAbleToSave] = useState(false);

  const handlePayerChange = (event) => {
    setDescriptionData((prevData) => ({
      ...prevData,
      payer_id: event.target.value,
    }));
  };

  const handleSplitMembersChange = (event) => {
    const value = event.target.value;
    setDescriptionData((prevData) => ({
      ...prevData,
      split_members: typeof value === "string" ? value.split(",") : value,
    }));
  };

  // New handler for schedule change
  const handleScheduleChange = (event) => {
    setDescriptionData((prevData) => ({
      ...prevData,
      schedule_id: event.target.value,
    }));
  };

  useEffect(() => {
    const isEditing =
      !descriptionData.payer_id ||
      !descriptionData.total_amount ||
      !descriptionData.split_members ||
      descriptionData.split_members.length < 1;
    setIsAbleToSave(!isEditing);
  }, [descriptionData]);

  useEffect(() => {
    setDescriptionData((prevData) => ({
      ...prevData,
      title:
        searchParams.get("schedule_id") &&
        schedules.find(
          (schedule) => schedule?.schedule_id == searchParams.get("schedule_id")
        )?.title,
      split_members: initialSplitMembers,
    }));
  }, [initialSplitMembers]);

  useEffect(() => {
    if (!url) {
      navigate("/");
      return;
    } else {
      setUrl(url);
    }

    let initialSplitMembersTmp = [];

    if (Object.keys(itineraryInfo).length === 0) {
      getItn(url).then((data) => {
        if (!data) {
          navigate("/");
          return;
        }
        setItineraryInfo(data);
        initialSplitMembersTmp = data.users.map((user) => user.member_id);
        setInitialSplitMembers(initialSplitMembersTmp);
      });
    } else {
      initialSplitMembersTmp = itineraryInfo.users.map(
        (user) => user.member_id
      );
      setInitialSplitMembers(initialSplitMembersTmp);
    }

    // Fetch schedules
    getAllSchedules(url).then((data) => {
      setSchedules(data);
    });

    const initializeFormData = async () => {
      console.log(expense_id);
      if (expense_id !== null) {
        const responseData = await getExpenseDetail(url, expense_id);
        const expenseData = {
          title: responseData.expense.title,
          payer_id: responseData.expense.payer_id,
          total_amount: responseData.expense.total_amount,
          schedule_id: responseData.expense.schedule_id,
          memo: responseData.expense.memo,
          creator_id: responseData.expense.creator_id,
          thread_type: responseData.expense.thread_id,
          split_members: responseData.expense_split.map(
            (split) => split.split_member_id
          ),
        };
        setDescriptionData(expenseData);
        setChats(responseData.chat);
        setReactions(responseData.reaction);
        setThread(responseData.thread);
      } else {
        setDescriptionData((prevData) => ({
          ...prevData,
          split_members: initialSplitMembersTmp,
          schedule_id:
            searchParams.get("schedule_id") && searchParams.get("schedule_id"),
          payer_id:
            localStorage.getItem("loginUserId") ||
            itineraryInfo.users[0].member_id,
        }));
      }
    };

    initializeFormData();
  }, []);

  // useEffect(() => {
  //   if (!url || !expense_id) return;
  //   const interval = setInterval(async () => {
  //     const fetchInfo = await getExpenseDetail(url, expense_id);
  //     console.log(fetchInfo);
  //     if (fetchInfo.reaction && fetchInfo.reaction.length > 0) {
  //       setReactions(fetchInfo.reaction);
  //     }
  //     if (fetchInfo.chat && fetchInfo.chat.length > 0) {
  //       setChats(fetchInfo.chat);
  //     }
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [url, expense_id]);

  const validateForm = () => {
    let formErrors = {};

    if (!descriptionData.payer_id) {
      formErrors.payer_id = "支払い者は必須です。";
    }

    if (!descriptionData.total_amount) {
      formErrors.total_amount = "総額は必須です。";
    }

    if (
      !descriptionData.split_members ||
      descriptionData.split_members.length < 1
    ) {
      formErrors.split_members = "少なくとも一人は選択してください";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      return;
    }

    let updatedDescription =
      expense_id === null
        ? {
            ...descriptionData,
            title: descriptionData.title || "",
            creator_id:
              localStorage.getItem("loginUserId") ||
              itineraryInfo.users[0].member_id,
          }
        : {
            ...descriptionData,
            title: descriptionData.title || "",
          };
    console.log(updatedDescription.title);
    updatedDescription.total_amount = updatedDescription.total_amount
      ? String(updatedDescription.total_amount).replace(/[^0-9]/g, "")
      : "0";

    try {
      setIsSaving(true);

      if (expense_id === null) {
        await createExpense(url, updatedDescription);
        navigate(`/accounting/${url}`);
      } else {
        await updateExpense(url, expense_id, updatedDescription);
        navigate(`/accounting/${url}`);
      }
    } catch (err) {
      console.error("予定の保存に失敗しました:", err);
    } finally {
      setIsSaving(false);
    }
  };

  // 予定を削除する関数
  const handleDelete = async () => {
    if (window.confirm("本当にこの予定を削除しますか？")) {
      setIsSaving(true);
      try {
        await deleteExpense(url, expense_id);
        navigate(`/accounting/${url}`);
      } catch (err) {
        console.error("予定の削除に失敗しました:", err);
      } finally {
        setIsSaving(false);
      }
    }
  };

  if (
    loading ||
    !itineraryInfo ||
    isSaving ||
    !initialSplitMembers ||
    schedules.length === 0
  ) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThreadComponent
      threadId={thread?.thread_id}
      threadType={2}
      handleSaveClick={handleSaveClick}
      chatsData={chats}
      reactionsData={reactions}
      users={itineraryInfo.users}
      url={url}
      setChats={setChats}
      setReactions={setReactions}
      isAbleToSave={isAbleToSave}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h6">
            明細を{expense_id == null ? "追加" : "編集"}
          </Typography>
          {expense_id !== null && (
            <IconButton onClick={handleDelete} sx={{ color: "text.grey" }}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            name="title"
            value={descriptionData.title}
            onChange={(e) => {
              handleChange(e);
            }}
            label="タイトル（任意）"
            variant="outlined"
          />
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <FormControl fullWidth variant="outlined" error={!!errors.payer_id}>
            <InputLabel>支払者</InputLabel>
            <Select
              value={descriptionData.payer_id}
              onChange={(e) => {
                handlePayerChange(e);
              }}
              label="支払者"
            >
              {itineraryInfo.users.map((user) => (
                <MenuItem key={user.member_id} value={user.member_id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
            {errors.payer_id && (
              <p style={{ color: "red", fontSize: "small" }}>
                {errors.payer_id}
              </p>
            )}
          </FormControl>
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            autoFocus
            name="total_amount"
            value={descriptionData.total_amount}
            onChange={(e) => {
              handleChange(e);
            }}
            label="総額"
            variant="outlined"
            error={!!errors.total_amount}
            helperText={errors.total_amount}
          />
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errors.split_members}
          >
            <InputLabel>分割メンバー</InputLabel>
            <Select
              multiple
              value={descriptionData.split_members}
              onChange={handleSplitMembersChange}
              renderValue={(selected) =>
                selected
                  .map(
                    (id) =>
                      itineraryInfo.users.find((user) => user.member_id === id)
                        ?.name || id
                  )
                  .join(", ")
              }
              label="分割メンバー"
            >
              {itineraryInfo.users.map((user) => (
                <MenuItem key={user.member_id} value={user.member_id}>
                  <Checkbox
                    checked={descriptionData.split_members.includes(
                      user.member_id
                    )}
                  />
                  <ListItemText primary={user.name} />
                </MenuItem>
              ))}
            </Select>
            {errors.split_members && (
              <p style={{ color: "red", fontSize: "small" }}>
                {errors.split_members}
              </p>
            )}
          </FormControl>
        </Box>

        {/* スケジュール選択フィールド */}
        <Box sx={{ marginBottom: 2 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>スケジュール（任意）</InputLabel>
            <Select
              value={descriptionData.schedule_id || ""}
              onChange={handleScheduleChange}
              label="スケジュール（任意）"
            >
              {schedules.map((schedule) => (
                <MenuItem
                  key={schedule.schedule_id}
                  value={schedule.schedule_id}
                >
                  {schedule.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* メモ入力フィールド */}
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            name="memo"
            value={descriptionData.memo}
            onChange={(e) => {
              handleChange(e);
            }}
            label="メモ"
            variant="outlined"
            multiline
            minRows={3}
          />
        </Box>
      </Box>
    </ThreadComponent>
  );
};

export default DescriptionDetail;
