export const getUserName = (users, creator_id) => {
  const user = users.find((user) => user.member_id === creator_id);
  return user ? user.name : null;
};

// URLを検出して分割するヘルパー関数
export const parseContentWithUrls = (content) => {
  // URLを検出する正規表現
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // URLとテキストを交互に格納する配列
  const result = [];

  // 最後に処理した位置
  let lastIndex = 0;

  // URLを検索して処理
  content.replace(urlRegex, (match, url, offset) => {
    // URLの前のテキストを追加
    if (offset > lastIndex) {
      result.push({
        type: "text",
        content: content.slice(lastIndex, offset),
      });
    }

    // URLを追加
    result.push({
      type: "url",
      content: url,
    });

    lastIndex = offset + match.length;
  });

  // 残りのテキストを追加
  if (lastIndex < content.length) {
    result.push({
      type: "text",
      content: content.slice(lastIndex),
    });
  }

  return result;
};
