import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  IconButton,
  CircularProgress,
  Button,
  SwipeableDrawer,
  useTheme,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getReplies, addReply, getReactions } from "../Api/threads/thread";
import { useNavigate } from "react-router-dom";
import { addReaction } from "../Api/threads/addReaction";
import { addChat } from "../Api/threads/addChat";
import { set } from "date-fns";
import { fi } from "date-fns/locale";

const ThreadComponent = ({
  children,
  threadId,
  threadType,
  handleSaveClick,
  chatsData,
  reactionsData,
  users,
  url,
  setChats,
  setReactions,
  isViewing,
  setIsEditing,
  isAbleToSave,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [replies, setReplies] = useState([]);
  const [newReply, setNewReply] = useState("");
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [reactionCounts, setReactionCounts] = useState({});
  const [isLiked, setIsLiked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setReplies(chatsData);
    const counts = reactionsData.reduce((acc, reaction) => {
      acc[reaction.reaction_type] = (acc[reaction.reaction_type] || 0) + 1;
      return acc;
    }, {});
    // Check if the current user has liked the thread

    setIsLiked(
      !!reactionsData.find(
        (reaction) =>
          reaction.reaction_type === "01" &&
          reaction.member_id == localStorage.getItem("loginUserId")
      )
    );
    console.log(reactionsData);
    setReactionCounts(counts);
  }, [threadId, chatsData]);

  const handleLikeClick = async () => {
    if (isLiked) {
      return;
    }
    setReactionCounts((prev) => ({
      ...prev,
      "01": (prev["01"] || 0) + 1,
    }));
    setIsLiked(true);

    try {
      const responseReactionsData = await addReaction(
        url,
        threadId,
        "01",
        localStorage.getItem("loginUserId") || users[0].member_id
      );
      setReactions(responseReactionsData);
    } catch (err) {
      console.error("リアクションの追加に失敗しました:", err);
      setError("リアクションの追加に失敗しました");
    }
  };

  const handleReplyChange = (event) => {
    setNewReply(event.target.value);
  };

  const handleReplySubmit = async () => {
    if (newReply.trim()) {
      setIsLoading(true);
      try {
        const responseChatsData = await addChat(
          url,
          threadId,
          newReply.trim(),
          localStorage.getItem("loginUserId") || users[0].member_id
        );
        setReplies(responseChatsData);
        setChats(responseChatsData);
        setNewReply("");
      } catch (err) {
        console.error("返信の追加に失敗しました:", err);
        setError("返信の追加に失敗しました");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBackClick = () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes("post")) {
      navigate(`/notes/${url}`);
    } else if (currentPath.includes("event")) {
      navigate(`/calendar/${url}`);
    } else if (currentPath.includes("description")) {
      navigate(`/accounting/${url}`);
    } else {
      navigate();
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 2 }}>
      {/* 戻るボタンと保存ボタン */}
      {!isViewing || threadId === null ? (
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <IconButton
            onClick={
              (threadType == "3" || threadType == "1") && threadId !== null
                ? () => {
                    setIsEditing(false);
                  }
                : handleBackClick
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <Button
            variant="contained"
            onClick={handleSaveClick}
            sx={{
              borderRadius: "50px",
              paddingX: 3,
              backgroundColor: "primary.main",
              color: "text.white",
            }}
            disabled={!isAbleToSave}
          >
            保存
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
      )}

      {/* 子コンポーネントの内容（タイトル、内容など） */}
      {children}

      {/* いいねアイコンと返信タブボタン */}
      {threadId !== null && threadId !== undefined && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "fixed",
            bottom: 60,
            right: 16,
            py: 0.5,
            px: 2,
            borderRadius: "20px",
            backgroundColor: "primary.bg",
            boxShadow: 1,
            zIndex: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <IconButton
              onClick={handleLikeClick}
              color={isLiked ? "primary" : "default"}
              sx={{ padding: 0 }}
            >
              <ThumbUpIcon />
            </IconButton>
            <Typography variant="body2" sx={{ ml: 1 }}>
              {reactionCounts["01"] || 0} いいね
            </Typography>
          </Box>
          <Button
            onClick={toggleDrawer(true)}
            sx={{
              color: theme.palette.primary.main,
              textTransform: "none",
              fontSize: "16px",
              p: 0,
            }}
          >
            返信 {replies.length}件
          </Button>
        </Box>
      )}

      {/* 返信リストと返信入力を含むスライドドロワー */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ zIndex: 20000 }}
      >
        <Box
          sx={{
            height: "60vh",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* 返信リスト */}
          <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
            <Typography variant="h6">返信</Typography>

            <List>
              {replies.length > 0 ? (
                replies.map((reply) => {
                  const user = users?.find(
                    (u) => u.member_id == reply.creator_id
                  );
                  const userName = user ? user.name : "Unknown User";
                  return (
                    <ListItem key={reply.chat_id} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar></Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle2" sx={{ mr: 1 }}>
                              {userName}
                            </Typography>
                            <Typography variant="caption" color="text.grey">
                              {new Date(reply.created_at).toLocaleString()}
                            </Typography>
                          </Box>
                        }
                        secondary={reply.content}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Typography variant="body2" color="text.grey">
                  まだ返信がありません。
                </Typography>
              )}
            </List>
          </Box>

          {/* 返信入力フォーム */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "text.white",
              paddingTop: 1,
              paddingBottom: 1,
              zIndex: 20000,
            }}
          >
            <TextField
              fullWidth
              value={newReply}
              onChange={handleReplyChange}
              label="返信を入力"
              variant="outlined"
              size="small"
              sx={{
                marginRight: "8px",
              }}
            />
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <IconButton
                color="primary"
                onClick={handleReplySubmit}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
              >
                <SendIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default ThreadComponent;
