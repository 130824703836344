import axios from "axios";
import { API_URL } from "../../shared/constant";

const getAllPosts = async (url) => {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
  try {
    const response = await axiosInstance.get(
      `${API_URL.NOTE}/all_detail/${url}`
    );
    const result = response.data;

    // 新しいデータ構造に基づいて整形
    const formattedPosts = result.notes.map((noteData, index) => {
      const note = noteData.note;
      const likes = noteData.reaction.filter(
        (r) => r.reaction_type === "01"
      ).length;
      const comments = noteData.chat.length;
      return {
        note_id: note.note_id,
        thread_id: noteData.thread.thread_id,
        title: note.title,
        content: note.content,
        likes: likes,
        reactions: noteData.reaction,
        chats: noteData.chat,
        comments: comments,
        thread: result.notes[index].thread,
      };
    });

    // Sort by thread.updated_at in ascending order
    formattedPosts.sort(
      (a, b) => new Date(b.thread.updated_at) - new Date(a.thread.updated_at)
    );

    console.log("result", formattedPosts);

    return formattedPosts;
  } catch (error) {
    console.error("Error fetching schedules:", error);
    return [];
  }
};

export default getAllPosts;
