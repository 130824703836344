import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Button, CircularProgress } from "@mui/material";
import Dashboard from "./Dashboard";
import Description from "./Description";
import { useNavigate, useParams } from "react-router-dom";
import getItn from "../../Api/group/getItn";
import getAllSchedules from "../../Api/schedules/getAllSchedules"; // Import the function
import getAllExpenses from "../../Api/accounting/getAllExpenses";

const Account = ({ setUrl, itineraryInfo, setItineraryInfo }) => {
  const [currentTab, setCurrentTab] = useState(0); // デフォルトでタブ1(Dashboard)を表示
  const navigate = useNavigate();
  const { url } = useParams();
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);

  const handleNewExpenseClick = () => {
    navigate(`/description/${url}`);
  };

  // State for schedules
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      if (url) {
        setUrl(url);
      } else {
        navigate(`/`);
        return;
      }
      if (Object.keys(itineraryInfo).length === 0) {
        const data = await getItn(url);
        if (!data) {
          navigate(`/`);
          return;
        }
        setItineraryInfo(data);
      }
      const result = await getAllExpenses(url);
      setExpenses(result);
      setIsLoading(false);
      // Fetch schedules
      getAllSchedules(url).then((data) => {
        setSchedules(data);
      });
    };
    fetchPosts();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {/* タブの切り替え */}
      <Tabs value={currentTab} onChange={handleChange} centered>
        <Tab label="累計" />
        <Tab label="明細" />
      </Tabs>
      {isLoading || !itineraryInfo ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* タブの内容を表示 */}
          {currentTab === 0 && (
            <Dashboard
              itineraryInfo={itineraryInfo}
              expenses={expenses}
              url={url}
            />
          )}
          {currentTab === 1 && (
            <Description
              itineraryInfo={itineraryInfo}
              expenses={expenses}
              url={url}
              setIsLoading={setIsLoading}
              setExpenses={setExpenses}
              schedules={schedules}
            />
          )}
          <Button
            variant="contained"
            onClick={handleNewExpenseClick}
            sx={{
              position: "fixed",
              bottom: 70,
              right: 16,
              borderRadius: "50px",
              backgroundColor: "primary.main",
              color: "text.white",
              fontWeight: "bold",
              boxShadow: 0,
              py: 1,
              zIndex: 1000,
            }}
          >
            ＋ 明細を追加
          </Button>
        </>
      )}
    </Box>
  );
};

export default Account;
