import axios from "axios";
import { API_URL } from "../../shared/constant";

const updateItinerary = async (url, body) => {
  console.log(body);
  try {
    const response = await axios.put(`${API_URL.ITINERARY}/${url}`, body, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data.members;
  } catch (error) {
    console.error("Error fetching schedules:", error);
    throw error;
  }
};

export default updateItinerary;
