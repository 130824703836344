import { parseISO } from "date-fns";
import axios from "axios";
import { API_URL } from "../../shared/constant";

const getScheduleDetail = async (url, id, isDev) => {
  if (isDev) {
    const result = {
      schedule: {
        schedule_id: 166,
        title: "旅行３",
        start_time: "2024-09-26 12:00:00",
        end_time: "2024-09-26 16:00:00",
        schedule_type: "01",
        color_type: "01",
        link: "https://www.tokyo-zoo.net/zoo/ueno/",
        memo: "動物園だZooZoo",
        estimated_cost: 5000.0,
        actual_cost: null,
        created_at: "2024-09-27 23:41:58.844784",
        updated_at: "2024-09-27 23:41:58.844784",
        thread_id: 221,
      },
      thread: {
        thread_id: 221,
        creator_id: 200,
        created_at: "2024-09-27 23:41:58.844784",
        updated_at: "2024-09-28 00:04:36.562849",
      },
      reaction: [
        {
          reaction_id: 105,
          member_id: 200,
          reaction_type: "01",
          created_at: "2024-09-28 00:02:50.130402",
          updated_at: "2024-09-28 00:02:50.130402",
        },
        {
          reaction_id: 106,
          member_id: 201,
          reaction_type: "01",
          created_at: "2024-09-28 00:04:09.847178",
          updated_at: "2024-09-28 00:04:09.847178",
        },
      ],
      chat: [
        {
          chat_id: 106,
          creator_id: 200,
          content: "コメントだよー",
          created_at: "2024-09-28 00:02:19.819584",
          updated_at: "2024-09-28 00:02:19.819584",
        },
        {
          chat_id: 107,
          creator_id: 200,
          content: "ミス",
          created_at: "2024-09-28 00:04:36.562849",
          updated_at: "2024-09-28 00:04:36.562849",
        },
      ],
    };
    return {
      ...result,
      schedule: {
        ...result.schedule,
        start_time: parseISO(result.schedule.start_time),
        end_time: parseISO(result.schedule.end_time),
      },
    };
  } else {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });
    try {
      const response = await axiosInstance.get(
        `${API_URL.SCHEDULE}/detail/${url}?schedule_id=${id}`
      );
      const result = response.data;
      console.log(result);
      const sortedChatsData = response.data.chat.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      return {
        ...result,
        schedule: {
          ...result.schedule,
          start_time: parseISO(result.schedule.start_time),
          end_time: parseISO(result.schedule.end_time),
        },
        chat: sortedChatsData,
      };
    } catch (error) {
      console.error("Error fetching schedule detail:", error);
      return null;
    }
  }
};

export default getScheduleDetail;
